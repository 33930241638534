import React from "react";
import "../../assets/css/Media.css";

function Media2() {
    return (
        <>

            {/* <--------Social Media Icon Start-------------> */}
            <div className="social-media">
                <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
                <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
                <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
                <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
                <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
            </div>
            {/* <----------------Social Media Icon End----------------> */}

            <div className="container pt-3 0b-5">
                <div className="gallery-media1-title-main">
                    <p className="gallery-media1-title">Presidential CNG Initiative <span>Partnership Signing Ceremony </span> </p>
                </div>

                <div className="gallery-media2-section1-main">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <img className="gallery-media2-img1 rounded-4" src={require('../../assets/img/Media/gallery-media2-img1.jpg')} alt="images" loading="lazy" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <img className="gallery-media2-img2 rounded-4" src={require('../../assets/img/Media/gallery-media2-img2.jpg')} alt="images" loading="lazy" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <img className="gallery-media2-img2 rounded-4" src={require('../../assets/img/Media/gallery-media2-img3.jpg')} alt="images" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gallery-media2-section-images-main">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media2-img4.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media2-img5.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media2-img6.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        </>
    )
}

export default Media2;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import data from './Apiurl/meta_api';


// connect css---------------------------------------------------------------
import "../assets/css/Aboutus.css";
// for slider  ( react-slick slick-carousel)----------------------------
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// animation-------------------------------------------------------------------------
import AOS from "aos";
import 'aos/dist/aos.css';

// Previous Arrow Component
const PrevArrow = (props) => (
    <div
        {...props}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        className="slick-prev2"
    >
        <i class="fa-solid fa-chevron-left"></i>
    </div>

);
// Next Arrow Component
const NextArrow = (props) => (
    <div {...props}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        className="slick-next2">
        <i class="fa-solid fa-chevron-right"></i>
    </div>
);

function Aboutus() {

    const [meta, setMeta] = useState(null);

    const fetchData = async () => {
        try {
            const result = data;
         
            // Assuming you want to set metadata for the 'about_page'
            if (result && result.length > 0 && result[0].about_page) {
                setMeta(result[0].about_page);
                //   document.title = result.page1_title;
            }
        } catch (error) {
       
        }
    };

    useEffect(() => {
        fetchData();
        AOS.init({
          duration: 2000,
          once: true,
        });
      }, []);

    // this for map slider ----------------------------------------
    const settings1 = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };


    return (
        <>
            <Helmet>
                {meta &&
                    meta.meta &&
                    Object.values(meta.meta).map((metaItem) => (
                        <meta key={metaItem.name} {...metaItem} />
                    ))}
                {meta && meta.title && <title>{meta.title}</title>}
            </Helmet>

            {/* <--------Social Media Icon Start-------------> */}
            <div className="social-media">
                <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
                <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
                <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
                <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
                <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
            </div>
            {/* <--------Social Media Icon End-------------> */}

            <div>
                {/* -----------------------banner---------------------- */}
                <section className="banner-container">
                    <img
                        className="about-banner-image"
                        src={require("../assets/img/Aboutus/About -Banner.png")}
                        alt="Rolling Energy - CNG Solutions Banner"
                        loading="lazy"
                    />

                    <img className="banner-content-absolute" src={require('../assets/img/Aboutus/bannercontent.png')} alt="Cng Content" loading="lazy" />
                </section>
                {/* -----------------------banner end---------------------- */}



                {/*----------------- aboutus content1 code  ----------------- */}
                <section style={{ overflowX: "hidden" }}>
                    <div className="row treerow">
                        <div className="col-lg-6 col-md-12 col-sm-12 treeimagebacground">
                            <img className="handtreeimage" src={require("../assets/img/Aboutus/handtree.png")} alt="Green Tree in Hand - Environmental Conservation Concept" loading="lazy" />

                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 cardheadtag">
                            <div className="card cardabouttitle">
                                <p data-aos="fade-in">Rolling Energy is an independent oil and gas company founded in 2020 with the sole purpose of steering Nigeria towards a future where clean energy fuels our drive.</p>
                                <p data-aos="fade-in">Our focus lies in the conversion and installation of Compressed Natural Gas (CNG) kits, a crucial step in redefining the narrative of fuel for a cleaner future drive. Proudly backed by the government of the Federal Republic of Nigeria, Rolling Energy operates with a steadfast commitment to sustainability and innovation.</p>
                            </div>
                        </div>

                    </div>
                </section>
                {/*----------------- aboutus content1 end ----------------- */}


                {/*----------------- aboutus content2 code  ----------------- */}
                <section>
                    <div className="backgroundrow">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card cardbackground">
                                    <p data-aos="fade-in">Rolling Energy is more than a company; it is an instigation of thought for a better, cost-effective future. Specializing in the conversion and gas refill for domestic, commercial, industrial, and automotive applications. We operate at the intersection of innovation and cutting-edge technology, ensuring optimum and efficient performance.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <img className="personimages" src={require("../assets/img/Aboutus/person2.png")} alt="person" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </section>

                {/*----------------- aboutus content2 code end ----------------- */}


                {/* -------------------our values section------------------------- */}
                <section style={{ overflow: "hidden" }}>
                    <div >
                        <h1 className="headdingourvalue">Our <span style={{ color: "#CF8453" }}>Values</span></h1>
                    </div>
                    <div className="container">
                        <div className="row leafrow1">
                            <div data-aos="fade-up" className="col-lg-6 col-md-6 col-sm-12 text-center">
                                <img className=" leafimage" src={require("../assets/img/Aboutus/Sustainability.png")} alt="Sustainability Icon - Symbolizing Environmental Conservation" loading="lazy" />
                                <h4 className="about-cards-title">Sustainability</h4>
                                <p className="leafpararow1">We are committed to promoting and advancing sustainable practices in the energy sector, focusing on solutions that minimize environmental impact and contribute to a cleaner, greener future.</p>
                            </div>
                            <div data-aos="fade-up" className="col-lg-6 col-md-6 col-sm-12 text-center">
                                <img className=" leafimage" src={require("../assets/img/Aboutus/Affordability.png")} alt="Affordability Icon - Representing Cost-Effective Solutions" loading="lazy" />
                                <h4 className="about-cards-title">Affordability</h4>
                                <p className="leafpararow1">We believe in providing cost-effective and sustainable solutions that empower our customers to make environmentally conscious choices without compromising their budget.</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className="row  leafrow2">
                            <div data-aos="fade-up" className="col-lg-4 col-md-4 col-sm-12 text-center">
                                <img className=" leafimage" src={require("../assets/img/Aboutus/Accessibility.png")} alt="Accessibility Icon - Symbol of Inclusivity and Universal Access" loading="lazy" />
                                <h4 className="about-cards-title">Accessibility</h4>
                                <p className="leafpararow2">We are dedicated to removing barriers and ensuring that our services are available and affordable for both commercial enterprises and individual users.</p>
                            </div>
                            <div data-aos="fade-up" className="col-lg-4 col-md-4 col-sm-12 text-center">
                                <img className=" leafimage" src={require("../assets/img/Aboutus/Integrity.png")} alt="Integrity Icon - Symbolizing Trust and Honesty" loading="lazy" />
                                <h4 className="about-cards-title">Integrity</h4>
                                <p className="leafpararow2">We conduct our business transparently, ethically, and with a strong sense of responsibility, ensuring trust and credibility in all our endeavors.</p>
                            </div>
                            <div data-aos="fade-up" className="col-lg-4 col-md-4 col-sm-12 text-center">
                                <img className=" leafimage" src={require("../assets/img/Aboutus/Innovation.png")} alt="Innovation Icon - Symbolizing Creativity and Progress" loading="lazy" />
                                <h4 className="about-cards-title">Innovation</h4>
                                <p className="leafpararow2">We strive to stay at the forefront of technology, continuously seeking new and improved ways to deliver clean energy solutions and enhance our services.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ----------------------our value section end--------------------------------- */}



                {/* ------------------------------map section----------------------------------- */}
                <section style={{ overflowX: "hidden" , overflowY:"hidden"}}>
                    <div className="mapbackground">
                        <div className="overlay2">
                        </div>
                        <Slider className="slidertag"  {...settings1}>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">ABUJA</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/abuja-map-1.png")} alt="Map Images - Illustration of a Map, Abuja Selected" className="abuja-map-img" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">LAGOS</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/Lagos-map-1.png")} alt="Map Images - Illustration of a Map, LAGOS Selected" className="lagos-map-img" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">KADUNA</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/kaduna-map-1.png")} alt="Map Images - Illustration of a Map, Kaduna Selected" class="kaduna-map-img" loading="lazy" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">KWARA</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/kwara-map-1.png")} alt="Map Images - Illustration of a Map, Kwara Selected" className="kwara-map-img" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">KANO</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/kano-map-1.png")} alt="Map Images" className="kano-map-img" loading="lazy" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">SOKOTO</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/sokoto.map-1.png")} alt="Map Images" className="sokoto-map-img" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">MAIDUGURI</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/maiduguri-map-1.png")} alt="Map Images" className="maiduguri-map-img" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">YOLA</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/yola-map-1.png")} alt="Map Images" className="yola-map-img" loading="lazy" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="defaulttext">"Bringing Clean Energy Solutions Across Nigeria's Key Regions"</h3>
                                        <h1 data-aos="fade-right" className="abujatext">PLATEAU</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="map-container">
                                            <img src={require("../assets/img/Aboutus/plateau-map-1.png")} alt="Map Images" className="plateau-map-img" loading="lazy" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>

                </section>

                {/* ---------------------------map section end-------------------------------- */}

            </div>

        </>

    )
}
export default Aboutus;

import React from "react";
import "../../assets/css/Media.css";

function Media3() {
    return (
        <>
            {/* <--------Social Media Icon Start-------------> */}
            <div className="social-media">
                <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
                <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
                <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
                <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
                <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
            </div>
            {/* <----------------Social Media Icon End----------------> */}


            <div className="container pt-3 pb-5">
                <div className="gallery-media1-title-main">
                    <p className="gallery-media3-title">Nigeria’s CNG Revolution -<span>An Exclusive Stakeholders Event 2023 </span> </p>
                </div>

                <div className="gallery-media3-section-main pt-5 mt-4 pb-5">
                    <img className="gallery-media3-img rounded-4" src={require('../../assets/img/Media/gallery-media3-img1.jpg')} alt="images" loading="lazy" />

                </div>
            </div>

        </>
    )
}

export default Media3;
import React from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/Footer.css";

function Footer() {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

    return (

        <div class="footer-main-responsive container-fluid bg-dark text-light footer pt-5 pb-5 footer-background  mb-0 py-6 wow fadeIn" data-wow-delay="0.1s">
            <div class="container footer-container">
                <div class="row g-5">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <img className="logo-image" src={require('../../assets/img/logo-footer.png')} alt="Rolling Energy - Sustainable Energy Solutions Logo" />
                        <br />
                        <br />
                        <div className="footer-contact-us">
                            <h3 className="pt-3">Connect Us</h3>
                            {/* <p class="mb-2 p-0 text-white">enquiries@rollingenergyltd.com</p> */}
                            <p className="mb-2 p-0 text-white">
        <a href="mailto:enquiries@rollingenergyltd.com" className="footer-contact-us">enquiries@rollingenergyltd.com</a>
    </p>
                        </div>

                        <div className="social-medias">
                            <a target="blank" href="https://www.instagram.com/rolling_energy/"><i style={{ paddingRight: "10px" }} class=" fa-brands fa-instagram text-white py-3"></i></a>
                            <a target="blank" href=" https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook-f text-white py-3 px-2"></i></a>
                            <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter text-white py-3 px-2"></i></a>
                            <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin text-white py-3 px-2"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-9 col-sm-12">
                        <div className="row footer-top">
                            <h3 className="pb-3">Steering Nigeria towards a future where clean energy fuels our drive.</h3>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <ul className="list-unstyled footer-quick-link">
                                    <li><h3 class="text-light mb-4">Quick Link</h3></li>
                                    <li><NavLink to="/" className="font-size-20 btn btn-link text-white text-decoration-none p-1"  onClick={scrollToTop}><i className="fa-solid fa-angle-right"></i> Home</NavLink></li>
                                    <li><NavLink to="/about-us" className="font-size-20 btn btn-link text-white text-decoration-none p-1" onClick={scrollToTop}><i className="fa-solid fa-angle-right"></i> About Us</NavLink></li>
                                    <li><NavLink to="/services" className="font-size-20 btn btn-link text-white text-decoration-none p-1" onClick={scrollToTop}><i className="fa-solid fa-angle-right"></i> Services</NavLink></li>
                                    <li><NavLink to="/media" className="font-size-20 btn btn-link text-white text-decoration-none p-1" onClick={scrollToTop}><i className="fa-solid fa-angle-right"></i> Media</NavLink></li>
                                    <li><NavLink to="/contact-us" className="font-size-20 btn btn-link text-white text-decoration-none p-1" onClick={scrollToTop}><i className="fa-solid fa-angle-right"></i> Contact Us</NavLink></li>
                                </ul>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <ul className="list-unstyled footer-Legal">
                                    <li><h3 class="text-light mb-4">Legal</h3></li>
                                    <li><NavLink to="/privacy-policy" className="font-size-20 btn btn-link text-white text-decoration-none p-1" onClick={scrollToTop}><i className="fa-solid fa-angle-right"></i> Privacy Policy</NavLink></li>
                                </ul>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12 footer-head-office">
                                <h3 class="text-white mb-3">Head office</h3>
                                <div class="pt-2">
                                    <p className="font-size-20">Abuja,Nigeria</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p style={{ fontFamily: `'Inter',sans-serif` }} className="copy-rights text-center pt-2 para-font">&copy; Rolling Energy Private Limited - All Rights Reserved on 2024</p>
            </div>
        </div>
    )
}

export default Footer;
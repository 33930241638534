import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import "../assets/css/Contactus.css";
import data from './Apiurl/meta_api';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// animation
import AOS from "aos";
import "aos/dist/aos.css";

function Contactus() {

  const [meta, setMeta] = useState(null);

  const fetchData = async () => {
    try {
      const result = data;

      // Assuming you want to set metadata for the 'about_page'
      if (result && result.length > 0 && result[0].contact_page) {
        setMeta(result[0].contact_page);
        //   document.title = result.page1_title;
      }
    } catch (error) {
      // console.log('Error in fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  // <---------------Contact Form Email Send Start----------------->

  const [formData, setFormData] = useState({
    country_code: '+234',
    user_name: '',
    user_mobile: '',
    user_email: '',
    message: '',
  });

  const [mobileError, setMobileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'user_mobile') {
      const mobilePattern = /^\d{10}$/; // Allow only 10 digits for the mobile number
      setMobileError(mobilePattern.test(value) ? '' : 'Invalid mobile number');
    }
  };

  const handleInputFocus = (name) => {
    if (!formData[name]) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === 'country_code' ? '+234' : '',
      }));
    }
  };

  const handleInputClick = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'country_code' ? '+234' : '',
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (mobileError) {
      toast.error('Please provide a valid mobile number.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    // Disable the button and set loading state
    setIsLoading(true);


    try {
      fetch("https://yick2oefbi.execute-api.eu-north-1.amazonaws.com/RE/sendEmail", {
        mode: "no-cors",
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          formData
        )

      }).then((response) => {
        console.log("RESPONSE", response);
        if (response.status === 0) {
          toast.success('Email sent successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setFormData({
            country_code: '+234',
            user_name: '',
            user_mobile: '',
            user_email: '',
            message: '',
          });
        }
      })

    } catch (error) {
      toast.error('Failed to send email. Please try again later.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    finally {
      // Enable the button and reset loading state
      setIsLoading(false);
    }
  };


  // <---------------Contact Form Email Send End----------------->

  return (
    <>
      <Helmet>
        {/* {meta &&
          meta.meta &&
          Object.values(meta.meta).map((metaItem) => (
            <meta key={metaItem.name} {...metaItem} />
          ))} */}
          
        {meta && meta.title && <title>{meta.title}</title>}
        {meta && meta.meta && <meta name="description" content={meta.content} />}
      </Helmet>

      <ToastContainer />

      {/* <--------Social Media Icon Start-------------> */}
      <div className="social-media">
        <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
        <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
        <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
        <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
        <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
      </div>
      {/* <----------------Social Media Icon End----------------> */}


      {/* ------------------ banner content for contact us page start------------------------------ */}
      <section>
        <div className="contactus-banner">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 tagline-col">
              <img className="join-image" src={require("../assets/img/Contactus/join-contactus.png")} alt="Join with us" loading="lazy" />
              <h1 className="brand-name">ROLLING ENERGY</h1>
              <img className="tagline-image" src={require("../assets/img/Contactus/tagline2.png")} alt="Join with us Rolling Energy on this Transformative Journey" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      {/* ------------------ banner content for contact us page end------------------------------ */}
      {/* ----------------------------- form content start------------------------------------ */}
      <section>
        <div className="row font-section-row">
          <div className="col-lg-6 col-md-12 col-sm-12 text-center">
            <p className="contact-tagline" data-aos="fade-up">"Sustainably Powering Smiles<br></br> Choose CNG for a <br></br>
              <p className="colorfont-tag">Healthy pocket<span style={{ color: "black" }}>,</span> Healthy Family<span style={{ color: "black" }}>"</span></p></p>
            <img className="contact-image" src={require("../assets/img/Contactus/contactus-image.jpg")} alt="Sustainably Powering Smiles Choose CNG for a Healthy pocket, Healthy Family" loading="lazy" data-aos="fade-up" />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 text-center  my-4" data-aos="fade-up">
            <form onSubmit={sendEmail}>
              <div className="card bg-green cardinput1">
                <div className="card cardname">
                  <input
                    className="contact-input"
                    id="fullname"
                    name="user_name"
                    autoComplete="name"
                    placeholder="*Full Name"
                    value={formData.user_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='d-flex'>
                  <div className="card country-inputname">
                    <input
                      type="tel"
                      className="country-input"
                      id="countryCode"
                      name="country_code"
                      autoComplete="country-code"
                      placeholder={formData.country_code || '*Country Code'}
                      value={formData.country_code}
                      onChange={handleChange}
                      onClick={() => handleInputClick('country_code')}
                      onFocus={() => handleInputFocus('country_code')}
                      maxLength={4}
                      required
                    />
                  </div>
                  <div className="card contact-input-mobile">
                    <input
                      type="tel"
                      className="contact-input-inputbox"
                      id="mobile"
                      name="user_mobile"
                      autoComplete="mobile"
                      placeholder={formData.user_mobile || '*Mobile'}
                      value={formData.user_mobile}
                      onChange={handleChange}
                      onClick={() => handleInputClick('user_mobile')}
                      onFocus={() => handleInputFocus('user_mobile')}
                      maxLength={10}
                      required
                    />
                  </div>
                </div>
                <div className="card cardname">
                  <input
                    type="email"
                    className="contact-input"
                    id="email"
                    name="user_email"
                    autoComplete="off"
                    placeholder="*Email"
                    value={formData.user_email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="card cardname1">
                  <textarea
                    className="inputtag contact-input"
                    id="message"
                    autoComplete="message"
                    name="message"
                    placeholder="*How can we help?"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <button type="submit" value="send" className="btn btn-dark buttoncontact2" disabled={isLoading}>
                      {isLoading ? 'Sending...' : 'Contact Us'}
                    </button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* ----------------------------- form content end------------------------------------ */}
    </>


  )
}
export default Contactus;

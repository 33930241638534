import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Aboutus from "./component/Aboutus";
import apiData from "./component/Apiurl/meta_api";
import Contactus from "./component/Contactus";
import Home from "./component/Home";
import Footer from "./component/header-footer/Footer";
import Navbar from "./component/header-footer/Nav-bar";
// genos font family
import "@fontsource/genos";
import "@fontsource/genos/200-italic.css";
import "@fontsource/genos/200.css";
// Monserrat font family
import "@fontsource/montserrat-alternates";
import "@fontsource/montserrat-alternates/400-italic.css";
import "@fontsource/montserrat-alternates/400.css";
import ServiceMain from "./component/Services/Service-mainpage";
// Roboto font family
import "@fontsource/roboto";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/400.css";
import Media from "./component/Media/Media";
import PrivacyPolicy from "./component/PrivacyPolicy";

import CookieConsent from "react-cookie-consent";
import Media1 from "./component/Media/Media1";
import Media2 from "./component/Media/Media2";
import Media3 from "./component/Media/Media3";
import NotFound404 from "./component/NotFound404";

const App = () => {
  const [cookie, setCookie] = useState(null);

  const fetchData = async () => {
    try {
      // Accessing the first element of the array to get cookieContent
      setCookie(apiData[0].cookieContent);
    } catch (error) {
      console.log("Error in fetching data:", error);
    }
  };

  // Assuming your API response has the cookie data in result[0].cookieContent

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [loading]);

  return (
    <Router basename="/">
      {loading && (
        <div className="loading-screen">
          <div className="loader"></div>
          <img
            className="loading-img"
            src={require("./assets/img/RE LOGO.png")}
            alt="images"
          />
        </div>
      )}

      <div>
        <div>
          <Navbar />
          <Routes>
            <Route element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/services" element={<ServiceMain />} />
            <Route path="/media" element={<Media />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/ground-breaking-event-kaduna-cng-conversion-workshop"
              element={<Media1 />}
            />
            <Route
              path="/presidential-cng-initiative-partnership-signing-ceremony"
              element={<Media2 />}
            />
            <Route
              path="/nigeria-s-cng-revolution-an-exclusive-stakeholders-event-2023"
              element={<Media3 />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
          <Footer />
          <div className="container">
            <CookieConsent
              disableStyles={true}
              enableDeclineButton
              flipButtons
              location="bottom"
              buttonText="Allow"
              cookieName="myAwesomeCookieName2"
              declineButtonText="Deny"
              expires={150}
            >
              {cookie}
              {/* Rolling Energy uses cookies for analytics. By continuing to use our site, you agree to our use of cookies. */}
            </CookieConsent>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import data from '../Apiurl/meta_api';

import '../../assets/css/Services.css';
// animation
import AOS from "aos";
import "aos/dist/aos.css";



function ServiceMain() {

    const [meta, setMeta] = useState(null);

    const fetchData = async () => {
        try {

            const result = data;
            // Assuming you want to set metadata for the 'about_page'
            if (result && result.length > 0 && result[0].service_page) {
                setMeta(result[0].service_page);
                //   document.title = result.page1_title;
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
        AOS.init({
            duration: 2000,
            once: true,
        });
    }, []);

    return (
        <>
            <Helmet>
                {meta &&
                    meta.meta &&
                    Object.values(meta.meta).map((metaItem) => (
                        <meta key={metaItem.name} {...metaItem} />
                    ))}
                {meta && meta.title && <title>{meta.title}</title>}
            </Helmet>

            {/* <--------Social Media Icon Start-------------> */}
            <div className="social-media">
                <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
                <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
                <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
                <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
                <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
            </div>
            {/* <----------------Social Media Icon End----------------> */}

            {/*----------------- Banner Section Start ------------------*/}

            <section>
                <div className="service-banner">
                    <img className="service-banner-image" src={require('../../assets/img/Services-image/servicebanner.jpg')} alt="banner for service page" loading="lazy" />
                    <div className="service-banner-content">
                        <img className="service-banner-content" src={require('../../assets/img/Services-image/service-bannercontent.png')} alt="Empowring Tomorrow's Mobility Comprehensive CNG Solutions & Training for a Sutainable Future" loading="lazy" />
                    </div>
                </div>
            </section>
            {/*----------------- Banner Section End ------------------*/}

            <div className="container-fluid service-main">

                {/*------------ Service First Cards Section Start---------------*/}

                <section >
                    <div className="first-section-cards-main">
                        <h5 className="service-first-title text-center">CNG Conversion <span>Kits</span></h5>
                        <div className="mobile-view-show">
                            <img className="person-image" src={require('../../assets/img/Services-image/person-service.jpg')} alt="Rolling Energy CNG Service Team Member" loading="lazy" />
                        </div>
                        <div className="first-section-image-content">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="first-main-card">
                                        <div className="row first-card" data-aos="fade-right">
                                            <div className="col-lg-12">
                                                <p>State-of-the-art Compressed Natural Gas (CNG) Conversion Kits, revolutionizing the transportation landscape.</p>
                                            </div>
                                        </div>
                                        <div className="row second-card" data-aos="fade-right">
                                            <div className="col-lg-12">
                                                <p>Engineered for adaptability, our kits cater to a wide range of vehicles and power generators, making them suitable for both Vehicle CNG Conversion and Power Generator CNG Conversion.</p>
                                            </div>
                                        </div>
                                        <div className="row first-card" data-aos="fade-right">
                                            <div className="col-lg-12">
                                                <p>Addressing diverse customer needs, our kits offer a comprehensive solution for those seeking a greener commute or aiming to power equipment with eco-friendly energy.</p>
                                            </div>
                                        </div>
                                        <div className="row second-card" data-aos="fade-right">
                                            <div className="col-lg-12">
                                                <p>Significantly contribute to reducing carbon emissions, aligning with our commitment to environmental sustainability.</p>
                                            </div>
                                        </div>
                                        <div className="row first-card" data-aos="fade-right">
                                            <div className="col-lg-12">
                                                <p>Substantial long-term fuel cost savings, making sustainable choices accessible and economical for our customers.</p>
                                            </div>
                                        </div>
                                        <div className="row last-card" data-aos="fade-right">
                                            <div className="col-lg-12">
                                                <p>Dual focus on affordability and environmental impact underscores our commitment to driving positive change, benefiting both the customer's wallet and the environment.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 mt-5">
                                    <div className="first-main-image-card">
                                        <img className="person-image mobile-view-hide" src={require('../../assets/img/Services-image/person-service.jpg')} alt="Rolling Energy CNG Service Team Member" loading="lazy" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service First Cards Section End---------------*/}

                {/*---------- Service Image Title Section End-----------*/}
                <section>
                    <div className="pt-5">
                        <div className="container">
                            <p className="kit-content" data-aos="fade-up">The 4, 6, or 8-cylinder sequential kit features a Direct Injection System for precise Air and CNG mixing. Injectors, controlled by the ECM (Electronic Control Module) alongside Petrol Injectors, ensure accurate Gas and Air ratios through sensors.</p>
                            <div className="service-kit-images-main">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-4 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="kit-images" src={require("../../assets/img/Services-image/pressure-reducer.png")} alt="CNG Pressure Reducer for Rolling Energy Project" loading="lazy" />
                                            <p className="card-title pt-3">Pressure Reducer</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="kit-images-rotate" src={require("../../assets/img/Services-image/injector-rail.png")} alt="CNG Injector Rail for Rolling Energy Project" loading="lazy" />
                                            <p className="card-title pt-5" >Injector Rail</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="cng-filter-image" src={require("../../assets/img/Services-image/CNG-Filter.png")} alt="CNG Filter for Rolling Energy Project" loading="lazy" />
                                            <p className="card-title">CNG Filter</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 image-card " data-aos="fade-up">
                                        <div className="card cards-adjust tab-card-response text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Solenoid-Valve-image" src={require("../../assets/img/Services-image/Solenoid Valve.png")} alt="CNG Solenoid Valve by Rolling Energy" loading="lazy" />
                                            <p className="card-title pt-1">Solenoid Valve</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Electronic-Control-Unit-image" src={require("../../assets/img/Services-image/Electronic-Control-Unit.png")} alt="Electronic Control Unit for CNG Systems by Rolling Energy" loading="lazy" />
                                            <p className="card-title pt-4">Electronic Control Unit</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Coolant-Hose-image pt-3" src={require("../../assets/img/Services-image/Coolant-Hose.png")} alt="CNG Coolant Hose by Rolling Energy" loading="lazy" />
                                            <p className="card-title pt-5">Coolant Hose 10 mm</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Gas-Hose-image" src={require("../../assets/img/Services-image/Gas-Hose.png")} alt="CNG Gas Hose by Rolling Energy" loading="lazy" />
                                            <p className="card-title pt-4">Gas Hose 12 mm</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Vacuum-Hose-image" src={require("../../assets/img/Services-image/Vacuum-Hose.png")} alt="CNG Vacuum Hose by Rolling Energy" loading="lazy" />
                                            <p className="card-title">Vacuum Hose 8 mm</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Stainless-steel-Tube-image" src={require("../../assets/img/Services-image/Stainless-steel-Tube.png")} alt="CNG Stainless Steel Tube by Rolling Energy" loading="lazy" />
                                            <p className="card-title">Stainless steel Tube</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Filling-Valve-image" src={require("../../assets/img/Services-image/Filling-Valve.png")} alt="CNG Filling Valve by Rolling Energy" loading="lazy" />
                                            <p className="card-title">Filling Valve</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Hardware-Packet-image" src={require("../../assets/img/Services-image/Hardware-Packet.png")} alt="CNG Hardware Packet by Rolling Energy" loading="lazy" />
                                            <p className="card-title pt-1">Hardware Packet</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Wiring-Harness-image" src={require("../../assets/img/Services-image/Wiring-Harness.png")} alt="High-Quality CNG Wiring Harness Manufactured by Rolling Energy for Sustainable Vehicles" loading="lazy" />
                                            <p className="card-title pt-3">Wiring Harness</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Pressure-Gauge-image" src={require("../../assets/img/Services-image/Pressure-Gauge.png")} alt="CNG Pressure Gauge - Monitoring Pressure Levels in Compressed Natural Gas Systems" loading="lazy" />
                                            <p className="card-title">Pressure Gauge</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 pt-5 image-card" data-aos="fade-up">
                                        <div className="card cards-adjust text-center d-flex justify-content-center align-items-center border-0">
                                            <img className="Filling-Receptacle-images" src={require("../../assets/img/Services-image/Filling-Receptacle.png")} alt="CNG Filling Receptacle by Rolling Energy" loading="lazy" />
                                            <p className="card-title">Filling Receptacle ngv 1</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*---------- Service Image Title Section End-----------*/}

                {/*------------ Service Second Cards Section Start---------------*/}
                <section className="second-cards-main">
                    <div>
                        <h5 className="service-second-title text-center">CNG Kit <span>Installation</span></h5>
                    </div>
                    <div className="second-section-image-content pt-4">
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div className="second-section-image-main">
                                    <img className="vehicle-image" src={require('../../assets/img/Services-image/cng-four-wheeler.jpg')} alt="CNG Four-Wheeler by Rolling Energy" loading="lazy" />
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="second-main-card">
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>At Rolling Energy, we emphasize the significance of not only innovative CNG Conversion Kits but also the precision and expertise embedded in our installation process.</p>
                                        </div>
                                    </div>
                                    <div className="row second-second-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Our installation process is a personalized experience, where skilled technicians tailor their expertise to various vehicles, be it bikes, tricycles, compact cars, heavy-duty trucks, or power generators.</p>
                                        </div>
                                    </div>
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Meticulous attention to detail ensures that every component is seamlessly integrated, guaranteeing optimal performance and efficiency aligned with your vehicle's specifications.</p>
                                        </div>
                                    </div>
                                    <div className="row second-second-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Flawless integration of components during the CNG Conversion Installation, covering everything from the fuel delivery system to engine modifications.</p>
                                        </div>
                                    </div>
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Our technicians adhere to the highest industry standards, leaving no room for compromise in the quality and precision of the CNG Conversion Installation.</p>
                                        </div>
                                    </div>
                                    <div className="row second-last-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Paramount importance is placed on customer satisfaction, making the installation process more than a modification.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service Second Cards Section End---------------*/}

                {/*------------ Service Third Cards Section Start---------------*/}
                <section className="third-cards-main">
                    <div>
                        <h5 className="service-first-title text-center">CNG Vehicle and Power Generator <span>Maintenance</span></h5>
                        <div className="vehicle-mobile-view-visible">
                            <img className="vehicle-car-image" src={require('../../assets/img/Services-image/cng-service-vehicle.jpg')} alt="CNG Service Vehicle - Rolling Energy's Eco-Friendly Maintenance and Support Vehicle for Compressed Natural Gas Systems" loading="lazy" />
                        </div>

                    </div>

                    <div className="first-section-image-content pt-4">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="first-main-card">
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Comprehensive range of CNG maintenance services, designed to ensure optimal performance for converted vehicles.</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Certified technicians offer routine check-ups and specialized diagnostics for two-wheelers, three-wheelers, and heavy-duty vehicles, guaranteeing reliability and efficiency on every journey.</p>
                                        </div>
                                    </div>
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Power Generator Maintenance, ensuring uninterrupted and efficient power solutions. From routine inspections to preventive maintenance.</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Rolling Energy keeps power generators in top-notch condition, providing clean and reliable energy to power your world.</p>
                                        </div>
                                    </div>
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Customizable maintenance plans cater to specific requirements, whether a fleet of commercial vehicles or relying on power generators for industrial needs.</p>
                                        </div>
                                    </div>
                                    <div className="row last-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Reliability and efficiency are guaranteed for both CNG vehicles and power generators, creating a seamless and worry-free experience for our customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div className="first-main-image-card">
                                    <img className="vehicle-car-image vehicle-mobile-view-hide" src={require('../../assets/img/Services-image/cng-service-vehicle.jpg')} alt="CNG Service Vehicle - Rolling Energy's Eco-Friendly Maintenance and Support Vehicle for Compressed Natural Gas Systems" loading="lazy" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service Third Cards Section End---------------*/}

                {/*------------ Service fourth Cards Section Start---------------*/}
                <section className="fourth-cards-main">
                    <div>
                        <h5 className="service-second-title text-center">CNG Conversion <span>Workshops</span></h5>
                    </div>
                    <div className="second-section-image-content pt-4">
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div className="second-section-image-main">
                                    <img className="vehicle2-image" src={require('../../assets/img/Services-image/cng-workshop.jpg')} alt="CNG Workshop - Rolling Energy's Service Facility for Compressed Natural Gas Vehicles Maintenance and Repairs" loading="lazy" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="second-main-card">
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>The hub for excellence in the installation and maintenance of both vehicles and power generators.</p>
                                        </div>
                                    </div>
                                    <div className="row second-second-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Certified technicians equipped with the latest industry knowledge.</p>
                                        </div>
                                    </div>
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Cutting-edge technologies and industry best practices play a crucial role in enhancing efficiency and sustainability.</p>
                                        </div>
                                    </div>
                                    <div className="row second-second-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Safety is non-negotiable at Rolling Energy. Our workshops emphasize CNG safety guidelines and inspections, ensuring that every converted vehicle or power generator meets the highest safety standards.</p>
                                        </div>
                                    </div>
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Crafting the Future of Sustainable Transportation: Rolling Energy's CNG Conversion Workshops go beyond routine tasks; they are crafting the future of sustainable transportation.</p>
                                        </div>
                                    </div>
                                    <div className="row second-last-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Through excellence, innovation, and a steadfast commitment to safety, these workshops play a pivotal role in shaping a cleaner and greener tomorrow.</p>
                                        </div>
                                    </div>

                                    <div className="row second-last-card-logo-color" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Efficiency in the conversion process but also the sustainability that comes with each converted vehicle and power generator through state-of-the-art conversion techniques.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service fourth Cards Section End---------------*/}

                {/*------------ Service fifth Cards Section Start---------------*/}
                <section className="fifth-card-section-main">
                    <div>
                        <h5 className="service-first-title text-center">CNG Mother / Daughter <span>Stations</span></h5>
                        <div className="cng-station-mobile-view-visible pt-4">
                            <img className="cng-stations-image" src={require('../../assets/img/Services-image/cng-stations .jpg')} alt="CNG Stations by Rolling Energy" loading="lazy" />
                        </div>
                    </div>
                    <div className="second-section-image-content pt-4">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="first-main-card">
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Transforming refueling dynamics with strategically positioned CNG Mother/Daughter Refill Stations across major cities in Nigeria.</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Accessibility for both commercial and private CNG vehicle owners, reshaping the refueling experience nationwide.</p>
                                        </div>
                                    </div>
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Across key cities in Nigeria, Rolling Energy's CNG refill stations serve as beacons of convenience.The Mother Station, equipped with large storage capacities, serves as the primary hub</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Significantly reduce the distance and time required for refueling, ensuring that a Rolling Energy CNG Refill Station is within reach, revolutionizing the refueling landscape.</p>
                                        </div>
                                    </div>
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Daughter Stations strategically dispersed act as satellite refueling points, ensuring a continuous and efficient supply of CNG to vehicles across various neighborhoods.</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Backed by government approval, Rolling Energy's CNG Mother/Daughter Refill Stations adhere to stringent quality control and safety standards.</p>
                                        </div>
                                    </div>
                                    <div className="row fifth-last-before-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Technology enhances the overall refueling experience, making it a reliable and convenient choice for CNG users nationwide.</p>
                                        </div>
                                    </div>
                                    <div className="row fifth-last-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <div className="first-main-image-card">
                                                <p>Automated payment systems to real-time monitoring of gas levels, our stations leverage cutting-edge solutions for an enhanced refueling experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                {/* <div className="first-main-image-card"> */}
                                <img className="cng-stations-image cng-station-mobile-view-hide" src={require('../../assets/img/Services-image/cng-stations .jpg')} alt="CNG Stations by Rolling Energy" loading="lazy" />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service fifth Cards Section End---------------*/}

                {/*------------ Service sixth Cards Section Start---------------*/}

                <section className="sixth-cards-main">
                    <div>
                        <h5 className="service-second-title text-center">Virtual Pipelines / CNG <span>Delivery</span></h5>
                    </div>
                    <div className="second-section-image-content pt-4">
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div className="second-section-image-main">
                                    <img className="vehicle-image" src={require('../../assets/img/Services-image/cng-virtual-pipeline.jpg')} alt="CNG Virtual Pipeline - Infrastructure for Transporting Compressed Natural Gas Safely and Efficiently" loading="lazy" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 pt-4">
                                <div className="second-main-card">
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Our virtual pipelines / CNG delivery service, redefining the distribution of Compressed Natural Gas (CNG) and ensuring a seamless supply of clean energy across various sectors.</p>
                                        </div>
                                    </div>
                                    <div className="row second-second-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>From commercial entities or providing convenient refueling options for individuals, Rolling Energy's delivery solutions are designed for flexibility.</p>
                                        </div>
                                    </div>
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>accommodating diverse customer requirements and delivering clean energy where needed.</p>
                                        </div>
                                    </div>
                                    <div className="row second-second-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Real-time monitoring systems, enhancing the precision of the delivery process. From monitoring gas levels to tracking delivery routes,</p>
                                        </div>
                                    </div>
                                    <div className="row second-first-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>cutting-edge technology ensures accuracy and reliability, creating a seamless experience for our customers.</p>
                                        </div>
                                    </div>
                                    <div className="row sixth-last-card" data-aos="fade-left">
                                        <div className="col-lg-12">
                                            <p>Dynamic network connecting the central storage hub to various endpoints. This forward-thinking approach optimizes the delivery process, ensuring a continuous and efficient flow of CNG and providing a reliable and timely supply of clean energy.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service sixth Cards Section End---------------

                {/*------------ Service seventh Cards Section Start---------------*/}

                <section className="seventh-cards-main">
                    <div>
                        <h5 className="service-first-title text-center">CNG Reseller <span>Program</span> and <span>Training</span> </h5>
                        <div className="program-training-mobile-view-visible pt-4">
                            <img className="program-traning-image" src={require('../../assets/img/Services-image/program and training.jpg')} alt="CNG Program and Training - Rolling Energy" loading="lazy" />
                        </div>
                    </div>
                    <div className="first-section-image-content">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="first-main-card">
                                    <div className="row first-card">
                                        <div className="col-lg-12" data-aos="fade-right">
                                            <p>Unique opportunity for entrepreneurs to pioneer the clean energy revolution through our CNG Reseller Program and Training, contributing to the widespread adoption of Compressed Natural Gas (CNG) as a sustainable fuel.</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Hands-on experience, our training goes beyond theory, offering practical sessions in CNG conversion techniques.</p>
                                        </div>
                                    </div>
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Led by seasoned professionals in the CNG industry, our training provides expert guidance and insights into industry best practices.</p>
                                        </div>
                                    </div>
                                    <div className="row second-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Exclusive access to CNG conversion kits at competitive prices, positioning them as key players in the growing CNG market.</p>
                                        </div>
                                    </div>
                                    <div className="row first-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>Immersive learning experience that goes beyond product knowledge.</p>
                                        </div>
                                    </div>
                                    <div className="row last-card" data-aos="fade-right">
                                        <div className="col-lg-12">
                                            <p>It underscores the importance of customer satisfaction, safety, and effective business practices, equipping resellers with the tools for success in the clean energy market.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div className="first-main-image-card">
                                    <img className="program-traning-image program-training-mobile-view-hide" src={require('../../assets/img/Services-image/program and training.jpg')} alt="CNG Program and Training - Rolling Energy" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------ Service seventh Cards Section End---------------*/}

            </div>
        </>

    )
}

export default ServiceMain;
import React from "react";
import { NavLink } from "react-router-dom";


function NotFound404() {
    return (
        <div className="not-found">
            <h1>Oops!</h1>
            <h2>404 - Page Not Found</h2>
            <p>Looks Like We Can't Find What You're Looking For. <br/> Please Try Again </p>
            <NavLink to="/"> <button className="btn btn-button btn-lg">HOME</button></NavLink>
        </div>
    )
}

export default NotFound404;
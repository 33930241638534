import AOS from "aos";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/Home.css";
import data from "./Apiurl/meta_api";
import schemedata from "./Apiurl/schema_api";

import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// animation
import "aos/dist/aos.css";

import Banner from "./Banner";

//cards image import
import accesability from "../assets/img/Home/accesability.svg";
import assurance from "../assets/img/Home/assurance.svg";
import handshake from "../assets/img/Home/handshake.svg";
import money from "../assets/img/Home/money.svg";
import tree from "../assets/img/Home/tree.svg";

// contactus images import
import hand from "../assets/img/Home/handshake2.svg";
import makepayment from "../assets/img/Home/makepayment.svg";
import proofmoney from "../assets/img/Home/proof-money.svg";
import schedule from "../assets/img/Home/schedule.svg";
import setting from "../assets/img/Home/setting.svg";
import testing from "../assets/img/Home/testing.svg";
import vehicle from "../assets/img/Home/vehicle.svg";

// Previous Arrow
const PrevArrow = (props) => (
  <div
    {...props}
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    className="slick-prev1"
  >
    <i class="fa-solid fa-chevron-left"></i>
  </div>
);
// Next Arrow
const NextArrow = (props) => (
  <div
    {...props}
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    className="slick-next1"
  >
    <i class="fa-solid fa-chevron-right"></i>
  </div>
);

function Home() {
  const videourl =
    "https://rollingenergy.s3.eu-north-1.amazonaws.com/bannervideo2.mp4";

  const [meta, setMeta] = useState(null);
  const [schema, setschema] = useState(null);

  const fetchData = async () => {
    try {
      const result = data;
      const schemeresult = schemedata;
      if (result && result.length > 0 && result[0].home_page) {
        setMeta(result[0].home_page);
      }
      if (schemeresult[0].home_page) {
        setschema(schemeresult[0].home_page);
      }
    } catch (error) {
      // console.log('Error in fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  //  Cards Sliding setting
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  // ------------------------video Cards Start-----------------

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const cardStyle = {
    marginTop: "70px",
    height: isHovered ? "300px" : "300px",
    width: isHovered ? "650px" : "280px",
    marginLeft: isHovered ? "-350px" : "20px",
    backgroundColor: "#009846",
    borderRadius: "20px",
    transition: "height 0.1s ease, width 0.1s ease, margin-left 0.1s ease",
    position: "relative",
  };

  // mobile responsive

  const cardStyle3 = {
    marginTop: "70px",
    height: isHovered3 ? "465px" : "300px",
    width: isHovered3 ? "280px" : "280px",
    // marginBottom: isHovered3 ? '-300px' : '20px',
    backgroundColor: "#009846",
    borderRadius: "20px",
    transition: "height 0.3s ease, width 0.3s ease, margin-left 0.3s ease",
  };
  const cardStyle2 = {
    marginTop: "70px",
    height: isHovered2 ? "300px" : "300px",
    width: isHovered2 ? "650px" : "280px",
    marginLeft: isHovered2 ? "-350px" : "20px",
    backgroundColor: "#C68955",
    borderRadius: "20px",
    transition: "height 0.1s ease, width 0.1s ease, margin-left 0.1s ease",
    position: "relative",
  };
  // mobile responsive
  const cardStyle4 = {
    height: isHovered4 ? "350px" : "300px",
    width: isHovered4 ? "280px" : "280px",
    backgroundColor: "#C68955",
    borderRadius: "20px",
    transition: "height 0.3s ease, width 0.3s ease, margin-left 0.3s ease",
  };

  const hidetitle = {
    display: isHovered ? "none" : "flex",
  };
  const hidetitle2 = {
    display: isHovered2 ? "none" : "flex",
  };
  // mobile responsive
  const hidetitle3 = {
    display: isHovered3 ? "none" : "flex",
  };
  const hidetitle4 = {
    display: isHovered4 ? "none" : "flex",
  };

  const secondcontentshow = {
    display: isHovered ? "block" : "none",
    padding: "0px",
    marginLeft: isHovered ? "2px" : "200px",
    transition: "height 0.3s ease, width 0.4s ease, margin-left 0.4s ease",
  };
  const secondcontentshow2 = {
    display: isHovered2 ? "block" : "none",
    padding: "0px",
    marginLeft: isHovered2 ? "2px" : "200px",
    transition: "height 0.3s ease, width 0.4s ease, margin-left 0.4s ease",
  };

  // mobile responsive
  const secondcontentshow3 = {
    display: isHovered3 ? "block" : "none",
    padding: "10px",
  };
  const secondcontentshow4 = {
    display: isHovered4 ? "block" : "none",
    padding: "10px",
  };

  const addbuttonStyle = {
    cursor: "pointer",
  };

  const handleAddButtonHover = () => {
    setIsHovered(true);
  };

  const handleAddButtonLeave = () => {
    setIsHovered(false);
  };

  const handleAddButtonHover2 = () => {
    setIsHovered2(true);
  };

  const handleAddButtonLeave2 = () => {
    setIsHovered2(false);
  };

  // mobile responsive
  const handleAddButtonHover3 = () => {
    setIsHovered3(true);
  };

  const handleAddButtonLeave3 = () => {
    setIsHovered3(false);
  };

  const handleAddButtonHover4 = () => {
    setIsHovered4(true);
  };

  const handleAddButtonLeave4 = () => {
    setIsHovered4(false);
  };
  // ------------------------video Cards End-------------------

  // accordion--------------------
  const [activeAccordion, setActiveAccordion] = useState(null);
  const toggleAccordion = (accordionId) => {
    setActiveAccordion(activeAccordion === accordionId ? null : accordionId);
  };

  // ----------Contact Form Email Send-------------->
  const [formData, setFormData] = useState({
    country_code: "+234",
    user_name: "",
    user_mobile: "",
    user_email: "",
    message: "",
  });

  const [mobileError, setMobileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "user_mobile") {
      const mobilePattern = /^\d{10}$/; // Allow only 10 digits for the mobile number
      setMobileError(mobilePattern.test(value) ? "" : "Invalid mobile number");
    }
  };

  const handleInputFocus = (name) => {
    if (!formData[name]) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === "country_code" ? "+234" : "",
      }));
    }
  };

  const handleInputClick = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "country_code" ? "+234" : "",
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (mobileError) {
      toast.error("Please provide a valid mobile number.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    // Disable the button and set loading state
    setIsLoading(true);

    try {
      fetch(
        "https://yick2oefbi.execute-api.eu-north-1.amazonaws.com/RE/sendEmail",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      ).then((response) => {
        console.log("RESPONSE", response);
        if (response.status === 0) {
          toast.success("Email sent successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setFormData({
            country_code: "+234",
            user_name: "",
            user_mobile: "",
            user_email: "",
            message: "",
          });
        }
      });
    } catch (error) {
      toast.error("Failed to send email. Please try again later.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      // Enable the button and reset loading state
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />

      {/* <--------Social Media Icon Start-------------> */}
      <div className="social-media">
        <a target="blank" href="https://www.facebook.com/rollingenergyltd">
          <i class="fa-brands fa-facebook "></i>
        </a>
        <a target="blank" href="https://www.instagram.com/rolling_energy/">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a target="blank" href="https://twitter.com/rolling_energy">
          <i class="fa-brands fa-x-twitter"></i>
        </a>
        <a
          target="blank"
          href="https://www.linkedin.com/company/rollingenergy/"
        >
          <i class="fa-brands fa-linkedin"></i>
        </a>
        <a href="mailto:enquiries@rollingenergyltd.com">
          <i class="fa-regular fa-envelope"></i>
        </a>
      </div>

      <Banner />
      <div className="container-fluid p-0  overfolwtagt">
        {/* ----------------------- ABOUT US SECTION START------------------------------ */}

        <section>
          <Helmet>
            {meta &&
              meta.meta &&
              Object.values(meta.meta).map((metaItem) => (
                <meta key={metaItem.name} {...metaItem} />
              ))}
            {meta && meta.title && <title>{meta.title}</title>}
          </Helmet>

          {schema &&
            schema.schema &&
            Object.values(schema.schema).map((schemaItem, index) => (
              <Helmet key={index}>
                <script type="application/ld+json">
                  {JSON.stringify(schemaItem)}
                </script>
              </Helmet>
            ))}

          <div className="row p-0 mx-1 gridcontent">
            <div className="col-lg-5 col-md-12 col-sm-12  backimageforgrid">
              <div className="row gridspace about-responsive-margin">
                <div className="col-lg-6 col-md-6 col-sm-12 tablet-view">
                  <img
                    data-aos="fade-up"
                    className="grid1"
                    src={require("../assets/img/Home/grid1.png")}
                    alt="CNG-Powered Vehicles"
                    loading="lazy"
                  />
                  <img
                    data-aos="fade-up"
                    className="grid2"
                    src={require("../assets/img/Home/grid2.jpg")}
                    alt="CNG Tank"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    data-aos="zoom-in"
                    className="grid3"
                    src={require("../assets/img/Home/grid3.png")}
                    alt="CNG Manufacturing Factory"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 mt-5 about-content1">
              <p data-aos="fade-right">
                Join us in the future where fueling your vehicles and power
                generators isn't just a necessity but a conscious choice for a
                cleaner, sustainable, and affordable tomorrow. At Rolling
                Energy, we're on a mission to redefine the way you power your
                mobility, and it all begins with the revolutionary force of
                Compressed Natural Gas (CNG).
              </p>
              <br></br>
              <p data-aos="fade-right">
                The escalating costs of traditional fuel, coupled with growing
                concerns about environmental impact, underscore the urgent need
                for a viable alternative. Rolling Energy stands at the
                forefront, offering CNG as the answer – a cost-effective and
                eco-friendly solution that not only addresses the economic
                burden of conventional fuels but also aligns seamlessly with
                Nigeria's commitment to a cleaner, greener future.
              </p>
              <br></br>
              <p data-aos="fade-right">
                Embrace CNG with Rolling Energy and propel Nigeria towards a
                sustainable era of energy innovation.
              </p>
            </div>
          </div>
        </section>
        {/* ----------------------- ABOUT US SECTION END------------------------------ */}

        {/* <---------------------Cards Sliding Start-------------------> */}
        <section>
          <div className="backgroundcard">
            <div className="row ">
              <div className="col-lg-12 ">
                <div className="centertext">
                  <p data-aos="fade-right">
                    Precision-engineered conversion kits tailored for two,
                    three, and four wheelers, heavy vehicles including trucks
                    and buses. Comprehensive conversion kits for power
                    generators from 1kVA to 10kVA. We focus on fuel efficiency
                    and emission reduction, offering a reliable and eco-friendly
                    energy source.
                  </p>
                </div>
              </div>
            </div>
            <div className="container-fluid slider-card-main-padding">
              <div className="slider-padding">
                <Slider className="slidertop" {...settings}>
                  <div className="carousel-item">
                    <p>Generator</p>
                    <img
                      className="card-image"
                      src={require("../assets/img/Home/generator.jpg")}
                      alt="CNG-Powered Generator for Eco-Friendly Energy"
                      title="CNG-Powered Generator for Eco-Friendly Energy"
                      loading="lazy"
                    />
                  </div>
                  <div className="carousel-item">
                    <p>Two-wheeler</p>
                    <img
                      className="card-image"
                      src={require("../assets/img/Home/two wheeler.jpg")}
                      alt="Efficient CNG-Powered Two-Wheeler Transportation"
                      title="Efficient CNG-Powered Two-Wheeler Transportation"
                      loading="lazy"
                    />
                  </div>
                  <div className="carousel-item">
                    <p>Three-wheeler</p>
                    <img
                      className="card-image"
                      src={require("../assets/img/Home/three wheeler.png")}
                      alt="CNG Three-Wheeler for Green Mobility"
                      title="CNG Three-Wheeler for Green Mobility"
                      loading="lazy"
                    />
                  </div>
                  <div className="carousel-item">
                    <p>Four-wheeler</p>
                    <img
                      className="card-image"
                      src={require("../assets/img/Home/four wheeler.jpg")}
                      alt="Clean and Green CNG Vehicle"
                      title="Clean and Green CNG Vehicle"
                      loading="lazy"
                    />
                  </div>
                  <div className="carousel-item">
                    <p>Heavy Vehicles</p>
                    <img
                      className="card-image"
                      src={require("../assets/img/Home/heavy.jpg")}
                      alt="CNG-Powered Heavy Vehicle Innovation"
                      title="CNG-Powered Heavy Vehicle Innovation"
                      loading="lazy"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {/* <---------------------Cards Sliding End------------------------> */}

        {/* ------------------------Contact Us Start------------------------- */}
        <section>
          <div className="container-fluid p-0 m-0">
            <div className="contactus ">
              <div className="row">
                <p className="readytext">Ready To Convert?</p>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12 d-contact">
                  <div className="row">
                    <div
                      style={{ paddingLeft: "3rem" }}
                      className="col-lg-10 col-md-10 col-sm-12"
                    >
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row mobile-responsive">
                          <div className="col-lg-2 col-md-2 text-center">
                            <div className="text-center">
                              <img
                                className="imagecontent1"
                                src={require("../assets/img/Home/contactus.png")}
                                alt="contact icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg"
                                src={require("../assets/img/Home/contactimage.png")}
                                alt="contact content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <img
                              data-aos="fade-right"
                              className="arrowimage"
                              src={require("../assets/img/Home/arrow.png")}
                              alt="arrow icon"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-2 col-md-2 text-center">
                            <div style={{ textAlign: "center" }}>
                              <img
                                className="image"
                                src={vehicle}
                                alt="vehicle icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg2"
                                src={require("../assets/img/Home/vehiclesimage.png")}
                                alt="vehicle content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <img
                              data-aos="fade-right"
                              className="arrowimage"
                              src={require("../assets/img/Home/arrow.png")}
                              alt="arrow icon"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-2 col-md-2 text-center">
                            <div className="text-center">
                              <img
                                className="image"
                                src={proofmoney}
                                alt="proof of money icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg2"
                                src={require("../assets/img/Home/proofimage.png")}
                                alt="proof of owner content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <img
                              data-aos="fade-right"
                              className="arrowimage"
                              src={require("../assets/img/Home/arrow.png")}
                              alt="arrow icon"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-3 col-md-3 text-center">
                            <div className="text-center">
                              <img
                                className="image"
                                src={schedule}
                                alt="schedule icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg"
                                src={require("../assets/img/Home/scheduleimage.png")}
                                alt="schedule content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 text-center">
                            <div className="text-center">
                              <img
                                className="imagecontent1"
                                src={hand}
                                alt="enjoy our service icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg"
                                src={require("../assets/img/Home/servicetext.png")}
                                alt="enjoy our service"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <img
                              data-aos="fade-left"
                              className="arrowimage"
                              src={require("../assets/img/Home/right-arrow (2).png")}
                              alt="arrow icon"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-2 col-md-2 text-center">
                            <div className="text-center">
                              <img
                                className="image"
                                src={testing}
                                alt="testing icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg"
                                src={require("../assets/img/Home/testingtext.png")}
                                alt="texting content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <img
                              data-aos="fade-left"
                              className="arrowimage"
                              src={require("../assets/img/Home/right-arrow (2).png")}
                              alt="arrow icon"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-2 col-md-2 text-center">
                            <div className="text-center">
                              <img
                                className="image"
                                src={setting}
                                alt="setting icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg"
                                src={require("../assets/img/Home/installtext.png")}
                                alt="install content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <img
                              data-aos="fade-left"
                              className="arrowimage"
                              src={require("../assets/img/Home/right-arrow (2).png")}
                              alt="arrow icon"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-3 col-md-3 text-center">
                            <div className="text-center">
                              <img
                                className="image"
                                src={makepayment}
                                alt="payment icon"
                                loading="lazy"
                              />
                              <img
                                className="newimg"
                                src={require("../assets/img/Home/paymenttext.png")}
                                alt="payment content"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <img
                        className="semiline"
                        src={require("../assets/img/Home/semiline.png")}
                        data-aos="fade-left"
                        alt="semiline icon"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <section className="m-contact">
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <div className="m-card-main">
                      <img
                        className="m-contactus"
                        src={require("../assets/img/Home/contactus.png")}
                        alt=" contact us"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/contactimage.png")}
                        alt="contact content"
                        loading="lazy"
                      />
                    </div>
                    <img
                      data-aos="fade-right"
                      className="m-arrow"
                      src={require("../assets/img/Home/arrow.png")}
                      alt="arrow for the process indication"
                    />
                    <div className="m-card-main">
                      <img
                        className="m-vehicle"
                        src={vehicle}
                        alt="vehicle inspection"
                        loading="lazy"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/vehiclesimage.png")}
                        alt="vehicle content"
                        loading="lazy"
                      />
                    </div>
                    <img
                      data-aos="fade-right"
                      className="m-arrow"
                      src={require("../assets/img/Home/arrow.png")}
                      alt="arrow for the process indication"
                    />
                    <div className="m-card-main">
                      <img
                        className="m-proofmaoney"
                        src={proofmoney}
                        alt="proof of ownership"
                        loading="lazy"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/proofimage.png")}
                        alt="proof of owner content"
                        loading="lazy"
                      />
                    </div>
                    <img
                      data-aos="fade-right"
                      className="m-arrow"
                      src={require("../assets/img/Home/arrow.png")}
                      alt="arrow for the process indication"
                    />
                    <div className="m-card-main">
                      <img
                        className="m-schedule"
                        src={schedule}
                        alt="schedule an installation"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/scheduleimage.png")}
                        alt="schedule content"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mb-5">
                    <div className="m-card-main">
                      <img
                        className="m-schedule"
                        src={hand}
                        alt="enjoy our service "
                        loading="lazy"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/servicetext.png")}
                        alt="enjoy our service"
                        loading="lazy"
                      />
                    </div>
                    <img
                      data-aos="fade-left"
                      className="m-arrow"
                      src={require("../assets/img/Home/right-arrow (2).png")}
                      alt="arrow for the process indication"
                    />
                    <div className="m-card-main">
                      <img
                        className="m-schedule"
                        src={testing}
                        alt="testing "
                        loading="lazy"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/testingtext.png")}
                        alt="texting content"
                        loading="lazy"
                      />
                    </div>
                    <img
                      data-aos="fade-left"
                      className="m-arrow"
                      src={require("../assets/img/Home/right-arrow (2).png")}
                      alt="arrow for the process indication"
                    />
                    <div className="m-card-main">
                      <img
                        className="m-schedule"
                        src={setting}
                        alt="schedule an installation"
                        loading="lazy"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/installtext.png")}
                        alt="install content"
                        loading="lazy"
                      />
                    </div>
                    <img
                      data-aos="fade-left"
                      className="m-arrow"
                      src={require("../assets/img/Home/right-arrow (2).png")}
                      alt="arrow for the process indication"
                    />
                    <div className="m-card-main">
                      <img
                        className="m-schedule"
                        src={makepayment}
                        alt="make payment"
                        loading="lazy"
                      />
                      <img
                        className="m-newimg"
                        src={require("../assets/img/Home/paymenttext.png")}
                        alt="payment content"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div>
                    <img
                      className="mobile-res-semiline"
                      src={require("../assets/img/Home/semiline.png")}
                      alt="semiline curve"
                    />
                  </div>
                </section>

                <div className="col-lg-4 col-md-12 col-sm-12">
                  <form onSubmit={sendEmail}>
                    <div className="card cardinput">
                      <div className="card cardname">
                        <input
                          className="contact-input"
                          id="fullname"
                          name="user_name"
                          autoComplete="name"
                          placeholder="*Full Name"
                          value={formData.user_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="d-flex">
                        <div className="card country-inputname">
                          <input
                            type="tel"
                            className="country-input"
                            id="countryCode"
                            name="country_code"
                            autoComplete="country-code"
                            placeholder={
                              formData.country_code || "*Country Code"
                            }
                            value={formData.country_code}
                            onChange={handleChange}
                            onClick={() => handleInputClick("country_code")}
                            onFocus={() => handleInputFocus("country_code")}
                            maxLength={4}
                            required
                          />
                        </div>
                        <div className="card contact-input-mobile">
                          <input
                            type="tel"
                            className="contact-input-inputbox"
                            id="mobile"
                            name="user_mobile"
                            autoComplete="mobile"
                            placeholder={formData.user_mobile || "*Mobile"}
                            value={formData.user_mobile}
                            onChange={handleChange}
                            onClick={() => handleInputClick("user_mobile")}
                            onFocus={() => handleInputFocus("user_mobile")}
                            maxLength={10}
                            required
                          />
                        </div>
                      </div>
                      <div className="card cardname">
                        <input
                          type="email"
                          className="contact-input"
                          id="email"
                          name="user_email"
                          autoComplete="off"
                          placeholder="*Email"
                          value={formData.user_email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="card cardname1">
                        <textarea
                          className="inputtag contact-input"
                          id="message"
                          autoComplete="*message"
                          name="message"
                          placeholder="*How can we help?"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 buttonpadding">
                          <button
                            type="submit"
                            value="send"
                            className="btn btn-dark buttoncontact2"
                            disabled={isLoading}
                          >
                            {isLoading ? "Sending..." : "Contact Us"}
                          </button>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* /------------------------------Contact Us End---------------------------------- */}

        {/* /----------------------------Video Card Section Start---------------------------- */}
        <section>
          <div className="p-0 cardrow">
            {/* <video className="video" src={require('../assets/img/bannervideo2.mp4')} type="video/mp4"  loop autoPlay muted /> */}

            <video
              controls={false}
              playsInline
              style={{ controls: "none" }}
              className="video"
              autoPlay
              muted
              loop
            >
              <source src={videourl} type="video/mp4" />
            </video>

            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12"></div>
              <div className="col-lg-7 col-md-7 col-sm-12 cardsresponsive">
                <div
                  className="card cardadd1"
                  style={cardStyle}
                  onMouseEnter={handleAddButtonHover}
                  onMouseLeave={handleAddButtonLeave}
                >
                  <div className="row">
                    <span style={addbuttonStyle} className="addbutton">
                      <i className="fa-solid fa-circle-plus"></i>
                    </span>
                    <p style={hidetitle} className="addcontent">
                      Mother and Daughter Stations
                    </p>
                    <div data-aos="fade-left" style={secondcontentshow}>
                      <img
                        alt="images"
                        data-aos="fade-left"
                        className="secondcontentshow-para"
                        src={require("../assets/img/Home/content .png")}
                      />
                    </div>
                  </div>
                </div>

                {/* mobile responsive */}
                <div
                  className="card cardadd3"
                  style={cardStyle3}
                  onMouseEnter={handleAddButtonHover3}
                  onMouseLeave={handleAddButtonLeave3}
                >
                  <div className="row">
                    <span style={addbuttonStyle} className="addbutton">
                      <i class="fa-solid fa-circle-plus"></i>
                    </span>
                    <p style={hidetitle3} className="addcontent">
                      Mother and Daughter Stations
                    </p>
                    <div data-aos="fade-left" style={secondcontentshow3}>
                      <p
                        className="text-white secondcontentshow-title"
                        data-aos="fade-left"
                      >
                        Mother and Daughter Stations
                      </p>
                      <p
                        className="secondcontentshow-para"
                        data-aos="fade-left"
                      >
                        Our Mother Stations serves as the primary hub, equipped
                        with large storage capacities to meet the demands of a
                        bustling city. Our Daughter Stations, strategically
                        dispersed, act as satellite refueling points, ensuring a
                        continuous and efficient supply of CNG to vehicles
                        across Nigeria.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card cardadd2"
                  style={cardStyle2}
                  onMouseEnter={handleAddButtonHover2}
                  onMouseLeave={handleAddButtonLeave2}
                >
                  <div className="row">
                    <span style={addbuttonStyle} className="addbutton">
                      <i class="fa-solid fa-circle-plus"></i>
                    </span>
                    <p className="addcontent" style={hidetitle2}>
                      Virtual Pipelines
                    </p>
                    <div data-aos="fade-left" style={secondcontentshow2}>
                      <img
                        alt="images"
                        data-aos="fade-left"
                        className="secondcontentshow-para1"
                        src={require("../assets/img/Home/content2.png")}
                      />
                    </div>
                  </div>
                </div>
                {/* mobile responsive */}
                <div
                  className="card cardadd4"
                  style={cardStyle4}
                  onMouseEnter={handleAddButtonHover4}
                  onMouseLeave={handleAddButtonLeave4}
                >
                  <div className="row">
                    <span style={addbuttonStyle} className="addbutton">
                      <i class="fa-solid fa-circle-plus"></i>
                    </span>
                    <p className="addcontent" style={hidetitle4}>
                      Virtual Pipelines
                    </p>
                    <div data-aos="fade-left" style={secondcontentshow4}>
                      <p className="text-white secondcontentshow-title">
                        Virtual Pipelines
                      </p>
                      <p className="secondcontentshow-para">
                        Our virtual pipeline services redefines the way CNG is
                        distributed, this innovative approach ensures a seamless
                        supply of clean energy to various sectors in the comfort
                        of their homes and offices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* /==========================Video Card Section Start============================ */}

        {/* -------------different table between cng petrol diesel card------------------- */}
        <section>
          <div className="row  card-main">
            <div className="cards-mobile-none col-lg-6 col-md-12 col-sm-12 mt-5">
              <div className=" d-flex title row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="pt-5">
                    <div className="d-flex justify-content-center align-items-center p-0 circle">
                      <i
                        style={{ color: "#009846", fontSize: "15px" }}
                        class="fa-solid fa-circle"
                      >
                        {" "}
                      </i>
                      <p className="mt-3 mx-2 card-low"> Low</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center p-0">
                      <i
                        style={{ color: "#5E17EB", fontSize: "15px" }}
                        class="fa-solid fa-circle"
                      >
                        {" "}
                      </i>
                      <p className="mt-3 mx-2 card-high"> High</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <p className="cng-title">CNG</p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <p className="petrol-title">PETROL</p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <p className="diesel-title">DIESEL</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ce-card">
                <div className="row ">
                  <div className="col-lg-5 col-md-6 col-sm-6">
                    <div className="bg-dark text-white card-content-left pt-2 pb-3">
                      <p className="text-center border-bottom-line pt-1">
                        Price
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Running Cost
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Pollution Level
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Vehicle Price
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Maintenance
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Availability
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Future
                      </p>
                      <p className="text-center border-bottom-line pt-1">
                        Reliability
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="row cards ">
                      <div className="col-lg-4 col-md-4 col-sm-4 card-center-border">
                        <div className="text-white">
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                              data-aos-duration="1000"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 card-center-border">
                        <div className=" text-white">
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className=" text-white">
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* mobile response start */}
            <div className="card-mobile-res">
              <div className="row">
                <div className="d-flex">
                  <div className="pt-5 m-low-hign">
                    <div className="d-flex align-items-center p-0 circle">
                      <i
                        style={{ color: "#009846", fontSize: "12px" }}
                        class="fa-solid fa-circle"
                      >
                        {" "}
                      </i>
                      <p className="mt-3 mx-2 card-low"> Low</p>
                    </div>
                    <div className="d-flex align-items-center p-0">
                      <i
                        style={{ color: "#5E17EB", fontSize: "12px" }}
                        class="fa-solid fa-circle"
                      >
                        {" "}
                      </i>
                      <p className="mt-3 mx-2 card-high"> High</p>
                    </div>
                  </div>
                  <div>
                    <p className="m-cng-title">CNG</p>
                  </div>
                  <div>
                    <p className="m-petrol-title">PETROL</p>
                  </div>
                  <div>
                    <p className="m-diesel-title">DIESEL</p>
                  </div>
                </div>
              </div>
              <div className="card m-card-left border-4 rounded-4 border-dark">
                <div
                  style={{ maxWidth: "100%", flexWrap: "nowrap" }}
                  className="row"
                >
                  <div className="col-sm-4 left-content-width">
                    <div className="bg-dark text-white pb-2">
                      <p className="text-center m-border-bottom-line pt-1">
                        Price
                      </p>
                      <p className="text-center m-border-bottom-line  pt-1">
                        Running Cost
                      </p>
                      <p className="text-center m-border-bottom-line pt-1">
                        Pollution Level
                      </p>
                      <p className="text-center m-border-bottom-line pt-1">
                        Vehicle Price
                      </p>
                      <p className="text-center m-border-bottom-line pt-1">
                        Maintenance
                      </p>
                      <p className="text-center m-border-bottom-line pt-1">
                        Availability
                      </p>
                      <p className="text-center m-border-bottom-line pt-1">
                        Future
                      </p>
                      <p className="text-center m-border-bottom-line pt-1">
                        Reliability
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="d-flex">
                      <div>
                        <div className="text-white mx-4 ">
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="m-card-center-border">
                        <div className=" text-white mx-4">
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="m-card-center-border">
                        <div className=" text-white mx-4">
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-up">
                            <i
                              class="fa-solid fa-up-long"
                              data-aos="fade-in"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                          <p className="text-center  m-arrow-border-bottom pt-1 arrow-down">
                            <i
                              class="fa-solid fa-down-long"
                              data-aos="fade-out"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
              <img
                className="bulb-image"
                src={require("../assets/img/Home/bulb.jpg")}
                loading="lazy"
                alt="pulb images"
              />
            </div>
          </div>
        </section>

        {/* --------------------------------different table between cng petrol diesel card end------------------------------------ */}

        {/* -----------------------------------------------5 cards start----------------------------------------------------------- */}

        <section>
          <div className="gamechanger-cards-padding">
            <div>
              <h1 className="title2">
                So, what makes CNG a
                <span className="titlecolor"> game-changer?</span>
              </h1>
            </div>
            <div className="row cardrowspace">
              <div className="col-lg-3 col-md-6 col-sm-12 tab-view-center-card">
                <div className="card cardborder" data-aos="fade-up">
                  <div className="row cardrowheight">
                    <div className="col-lg-5 col-md-12 col-sm-12 image-center-card">
                      <img
                        className="tree-image"
                        src={tree}
                        alt="Lush Green Tree - Symbolizing Nature and Sustainability"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <h6 className="cards-title">Cleaner and Greener</h6>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "6px", paddingLeft: "6px" }}
                    class="row"
                  >
                    <p data-aos="fade-up" className="card-text text-center">
                      CNG is a low-carbon alternative to traditional fuels,
                      emitting significantly fewer pollutants. By choosing CNG,
                      you're not just driving; you're contributing to cleaner
                      air and a healthier environment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 tab-view-center-card">
                <div className="card cardborder" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 image-center-card">
                      <img
                        className="tree-image"
                        src={money}
                        alt="Money Icon - Financial Growth and Prosperity"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <h6 className="cards-title">
                        Cost-Effective and Efficient
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "4px", paddingLeft: "4px" }}
                    class="row"
                  >
                    <p
                      data-aos="fade-up"
                      className="card-text card-text-padding text-center"
                    >
                      Experience up to 25% - 40% cost savings without
                      compromising performance. CNG is not only affordable but
                      also provides the same or even better efficiency compared
                      to conventional fuels, making it a smart choice for
                      individuals and businesses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 tab-view-center-card">
                <div className="card cardborder" data-aos="fade-up">
                  <div className="row cardrowheight1">
                    <div className="col-lg-4 col-md-12 col-sm-12 image-center-card">
                      <img
                        style={{ width: "50px" }}
                        className="tree-image"
                        src={assurance}
                        alt="Assurance Icon - Symbol of Security and Confidence"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <h6 className="cards-title">
                        Government-Backed Assurance
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "6px", paddingLeft: "6px" }}
                    className="row"
                  >
                    <p
                      data-aos="fade-up"
                      className="pt-3 card-text text-center"
                    >
                      With the firm backing of the Federal Government, Rolling
                      Energy ensures that every step of the CNG journey, from
                      vehicle conversion to gas cylinder refilling, meets the
                      highest standards of quality, safety, and regulatory
                      compliance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 tab-view-show tab-view-center-card">
                <div className="card cardborder" data-aos="fade-up">
                  <div className="row cardrowheight">
                    <div className="col-lg-4 col-md-12 image-center-card">
                      <img
                        className="tree-image"
                        src={handshake}
                        alt="Symbol of Agreement and Partnership"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-8 col-md-12">
                      <h6 className="cards-title">
                        Strategic Partnerships for Excellence
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "6px", paddingLeft: "6px" }}
                    className="row"
                  >
                    <p data-aos="fade-up" className=" card-text text-center">
                      Our collaboration with global leaders in CNG kit
                      manufacturing empowers us to bring cutting-edge technology
                      and world-class expertise to the Nigerian market. Rolling
                      Energy is committed to providing you with the
                      best-in-class CNG solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row cardrowspace">
              <div className="col-lg-3 col-md-6 col-sm-12 tab-view-hide-card ">
                <div
                  className="card cardborder m-responsive-center"
                  data-aos="fade-up"
                >
                  <div className="row cardrowheight">
                    <div className="col-lg-4 col-md-12 image-center-card">
                      <img
                        className="tree-image"
                        src={handshake}
                        alt="Symbol of Agreement and Partnership"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-8 col-md-12">
                      <h6 className="cards-title">
                        Strategic Partnerships for Excellence
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "6px", paddingLeft: "6px" }}
                    className="row"
                  >
                    <p data-aos="fade-up" className=" card-text text-center">
                      Our collaboration with global leaders in CNG kit
                      manufacturing empowers us to bring cutting-edge technology
                      and world-class expertise to the Nigerian market. Rolling
                      Energy is committed to providing you with the
                      best-in-class CNG solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 tab-view-center-card">
                <div className="card cardborder " data-aos="fade-up">
                  <div className="row cardrowheight">
                    <div className="col-lg-5 col-md-12 image-center-card">
                      <img
                        className="tree-image"
                        src={accesability}
                        alt="Accessibility Icon - Symbol of Convenient Accessibility"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-7 col-md-12">
                      <h6 className="cards-title">Convenient Accessibility</h6>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "6px", paddingLeft: "6px" }}
                    className="row"
                  >
                    <p data-aos="fade-up" className="card-text text-center">
                      Rolling Energy strategically places CNG refill stations in
                      major cities, ensuring convenient access for individuals
                      and businesses alike. Join us in making CNG a readily
                      available and accessible choice for clean energy
                      consumption.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-3 col-sm-12">
                <img
                  data-aos="fade-left"
                  className="energyimage"
                  src={require("../assets/img/Home/energy.png")}
                  alt="Energy Logo - Symbolizing Power and Sustainability"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>

        {/* ----------------------------5 Card Section end ------------------------------- */}

        {/* ------------------------Faq Section Start---------------------------- */}
        <section>
          <div className="faq-padding-responsive">
            <div className="row mt-5 ">
              <h2 className="faq-title">FREQUENTLY ASKED QUESTIONS</h2>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <section style={{ padding: "10px 0px 0px 0px" }} id="faq">
                  <div className="container faq">
                    <div className="container">
                      <div style={{ marginTop: "-50px" }} className="row">
                        <div className="col-sm-12">
                          <div className="accordions">
                            {/* Accordion Item 1 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item1"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item1")}
                                data-tab="item1"
                              >
                                <p style={{ fontSize: "22px" }}>
                                  What is Compressed Natural Gas (CNG)?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item1"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item1"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item1" ? "active" : ""
                                }`}
                                id="item1"
                              >
                                <p>
                                  Compressed Natural Gas (CNG) is a clean and
                                  environmentally friendly alternative fuel made
                                  by compressing natural gas.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 2 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item2"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item2")}
                                data-tab="item2"
                              >
                                <p style={{ fontSize: "22px" }}>
                                  How does CNG benefit the environment?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item2"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item2"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item2" ? "active" : ""
                                }`}
                                id="item2"
                              >
                                <p>
                                  CNG produces fewer emissions than traditional
                                  fossil fuels, reducing carbon footprint and
                                  contributing to cleaner air.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 3 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item3"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item3")}
                                data-tab="item3"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  How does the cost of Compressed Natural Gas
                                  (CNG) compare to traditional fuels like petrol
                                  and diesel?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item3"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item3"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item3" ? "active" : ""
                                }`}
                                id="item3"
                              >
                                <p>
                                  CNG is significantly more affordable than
                                  petrol and diesel, with potential savings of
                                  up to 30-40% on fuel costs, making it a
                                  cost-effective and sustainable choice.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 4 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item4"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item4")}
                                data-tab="item4"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Can you provide a percentage estimate of the
                                  potential fuel cost savings by using Rolling
                                  Energy's CNG conversion kits for a typical
                                  commercial fleet?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item4"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item4"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item4" ? "active" : ""
                                }`}
                                id="item4"
                              >
                                <p>
                                  Rolling Energy's CNG conversion kits can lead
                                  to substantial savings, with potential cost
                                  reductions of approximately 25-35% for
                                  commercial fleets, offering a significant
                                  economic incentive for businesses.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 5 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item5"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item5")}
                                data-tab="item5"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  What vehicles can be converted to CNG?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item5"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item5"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item5" ? "active" : ""
                                }`}
                                id="item5"
                              >
                                <p>
                                  Various vehicles, including bikes, tricycles,
                                  cars, trucks, buses, and power generators, can
                                  be converted to run on CNG.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 6 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item6"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item6")}
                                data-tab="item6"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  How does Rolling Energy contribute to the CNG
                                  market in Nigeria?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item6"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item6"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item6" ? "active" : ""
                                }`}
                                id="item6"
                              >
                                <p>
                                  Rolling Energy is a leading importer and
                                  installer of CNG kits, making clean energy
                                  accessible through strategic installations
                                  across major cities in Nigeria.
                                </p>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item7"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item7")}
                                data-tab="item7"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Does Rolling Energy provide maintenance
                                  services for CNG vehicles?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item7"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item7"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item7" ? "active" : ""
                                }`}
                                id="item7"
                              >
                                <p>
                                  Yes, Rolling Energy offers comprehensive
                                  maintenance services for CNG vehicles,
                                  covering routine check-ups and specialized
                                  diagnostics.
                                </p>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item8"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item8")}
                                data-tab="item8"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Can I convert my power generator to run on
                                  CNG?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item8"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item8"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item8" ? "active" : ""
                                }`}
                                id="item8"
                              >
                                <p>
                                  Yes, Rolling Energy provides CNG conversion
                                  kits and services for power generators,
                                  offering an efficient and eco-friendly energy
                                  solution.
                                </p>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item9"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item9")}
                                data-tab="item9"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  How does Rolling Energy ensure the quality of
                                  its CNG conversion kits?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item9"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item9"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item9" ? "active" : ""
                                }`}
                                id="item9"
                              >
                                <p>
                                  Rolling Energy maintains quality control
                                  through rigorous testing and compliance with
                                  industry standards. Our kits are also backed
                                  by government approval.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <section style={{ padding: "0px 0px 0px 0px" }} id="faq">
                  <div className="container second-para-faq">
                    <div className="container">
                      <div className="row second-faq">
                        <div className="col-sm-12">
                          <div className="accordions">
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item10"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item10")}
                                data-tab="item10"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Are CNG conversion kits compatible with all
                                  vehicle models?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item10"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item10"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item10" ? "active" : ""
                                }`}
                                id="item10"
                              >
                                <p>
                                  Rolling Energy provides versatile CNG
                                  conversion kits compatible with a wide range
                                  of vehicle models for both personal and
                                  commercial use.
                                </p>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item11"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item11")}
                                data-tab="item11"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  What are the benefits of becoming a CNG
                                  Reseller with Rolling Energy?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item11"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item11"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item11" ? "active" : ""
                                }`}
                                id="item11"
                              >
                                <p>
                                  Resellers gain exclusive access to competitive
                                  CNG conversion kits, business support, and the
                                  opportunity to contribute to the growth of
                                  sustainable energy.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 3 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item12"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item12")}
                                data-tab="item12"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Is CNG as efficient as traditional fuels?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item12"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item12"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item12" ? "active" : ""
                                }`}
                                id="item12"
                              >
                                <p>
                                  CNG offers comparable or even better
                                  performance than traditional fuels, with the
                                  added benefits of being more cost-effective
                                  and environmentally friendly.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 4 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item14"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item14")}
                                data-tab="item14"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Does Rolling Energy provide training for CNG
                                  kit installation?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item14"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item14"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item14" ? "active" : ""
                                }`}
                                id="item14"
                              >
                                <p>
                                  Yes, our CNG Reseller Program includes
                                  hands-on training sessions conducted by
                                  seasoned professionals in the industry.
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 5 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item15"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item15")}
                                data-tab="item15"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  How does Virtual Pipelines / CNG Delivery
                                  work?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item15"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item15"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item15" ? "active" : ""
                                }`}
                                id="item15"
                              >
                                <p>
                                  Virtual Pipelines is the delivery of CNG to
                                  your doorstep for businesses and individuals.
                                  Contact us, place your order and we will be
                                  there to refill your gas cylinders.{" "}
                                </p>
                              </div>
                            </div>

                            {/* Accordion Item 6 */}
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item16"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item16")}
                                data-tab="item16"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Is CNG safe for vehicle operation?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item16"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item16"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item16" ? "active" : ""
                                }`}
                                id="item16"
                              >
                                <p>
                                  Yes, CNG is safe for vehicle operation.
                                  Rolling Energy emphasizes safety guidelines
                                  and conducts regular inspections to ensure
                                  compliance with industry standards.
                                </p>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item17"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item17")}
                                data-tab="item17"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Can I purchase CNG conversion kits directly
                                  from Rolling Energy?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item17"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item17"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item17" ? "active" : ""
                                }`}
                                id="item17"
                              >
                                <p>
                                  Yes, you can purchase CNG conversion kits
                                  directly from Rolling Energy. Contact our
                                  sales team for more information.
                                </p>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <div
                                className={`accordion-title ${
                                  activeAccordion === "item18"
                                    ? "active-title"
                                    : ""
                                }`}
                                onClick={() => toggleAccordion("item18")}
                                data-tab="item18"
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "22px",
                                  }}
                                >
                                  Can I convert my existing vehicle to CNG, or
                                  do I need to purchase a new one?{" "}
                                  <i
                                    style={{
                                      color:
                                        activeAccordion === "item18"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                    className={`fa fa-chevron-down ${
                                      activeAccordion === "item18"
                                        ? "chevron-top"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              </div>
                              <div
                                className={`accordion-content ${
                                  activeAccordion === "item18" ? "active" : ""
                                }`}
                                id="item18"
                              >
                                <p>
                                  Existing vehicles can be converted to run on
                                  CNG using Rolling Energy's conversion kits,
                                  eliminating the need to purchase a new vehicle
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/* ------------------------Faq Section End---------------------------- */}

        {/* ------------------------Faq Section End---------------------------- */}
      </div>
    </>
  );
}
export default Home;

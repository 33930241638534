import React from "react";
import '../assets/css/PrivacyPolicy.css';

function PrivayPolicy() {
    return (
        <>
            <div className="container pt-5 mb-5 card card-shadow">
                <div className="privacy-main">
                    <h2 className="privacy-title">Privacy Policy</h2>
                    <p className="pt-2">This Privacy policy between Rolling Energy Providers LTD and you, constitutes our commitment to your privacy on our administrative records, websites, social media platforms and premises.</p>
                    
                    <h4 className="pt-2">1.0 Your Privacy Rights</h4>
                    <p>This Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing and protection of your personal information. It applies to the Rolling Energy Providers LTD website and all database applications, services, tools and physical contact with us regardless of how you access or use them</p>
                    <p>You have the right to:</p>
                    <ul>
                        <li><p>Access your information. You can request a copy of the information we hold about you.</p></li>
                        <li><p>Correct your information. If you believe any of the information we hold about you is inaccurate, you can request that we correct it.</p></li>
                        <li><p>Delete your information. You can request that we delete your information from our records.</p></li>
                        <li><p>Opt out of communications. You can choose not to receive communications from us by contacting us at enquires@rollingenergyltd.com.</p></li>
                    </ul>
                    <h4 className="pt-2">2.0 Consent</h4>
                    <p>You accept this Privacy Policy when you give consent upon access to our platforms, or use our services, content, features, technologies or functions offered on our website, digital platforms or visit any of our offices for official or non-official purposes (collectively “Rolling Energy Providers LTD services”). This Policy governs the use of Rolling Energy Providers LTD services and intervention projects by our users and stakeholders unless otherwise agreed through a written contract.  We may amend this Privacy Policy at any time by posting a revised version on our website or placing such notice at conspicuous points at our office facilities. The revised version will be effective 7-days after publication.</p>
                    <h4>3.0 Your Personal Information</h4>
                    <p>When you use Rolling Energy Providers LTD Services, we collect information sent to us by your computer, mobile phone, or other electronic access device. The automatically collected information includes but is not limited to data about the pages you access, computer IP address, device ID or unique identifier, device type, geo-location information, computer and connection information, mobile network information, statistics on page views, traffic to and from the sites, referral URL, ad data, standard web log data, still and moving images.</p>
                    <p>We may also collect the information you provide us including but not limited to information on a web form, survey responses, email, phone number, the organization you represent, official position, correspondence with Rolling Energy Providers LTD support services, and telecommunication with Rolling Energy Providers LTD. </p>
                    <p>We may also use information provided by third parties like social media sites. Information about you provided by other sites is not controlled by Rolling Energy Providers LTD and we are therefore not liable for how they use it.</p>
                   
                    <h4 className="pt-2">4.0 What we do with your personal information</h4>
                    <p>We use your information to:</p>
                    <ul>
                        <li><p>Respond to your inquiries and provide you with the information or services you requested.</p></li>
                        <li><p>Improve our website and services.</p></li>
                        <li><p>Send you relevant information, such as news updates or statutory notices.</p></li>
                        <li><p>Comply with legal and regulatory requirements.</p></li>
                    </ul>

                    <h4 className="pt-2">5.0 Cookies</h4>
                    <p>Cookies are small files placed on your computer’s hard drive that enable the website to identify your computer as you view different pages. Cookies allow websites and applications to store your preferences to present contents, options, or functions that are specific to you. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only encrypted session cookies which are erased either after a predefined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from the user’s computer. They will typically store information in the form of a session identification that does not personally identify the user.</p>
                    
                    <h4 className="pt-2">6.0 How we protect your personal information</h4>
                    <p>We store and process your personal information on our computers in Nigeria. Where we need to transfer your data to another country, such a country must have an adequate data protection law. We will seek your consent when we need to send your data to a country without an adequate data protection law. We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure, and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.</p>

                    <h4 className="pt-2"> 7.0 How We Share your information with Rolling Energy Providers LTD and other users</h4>
                    <p>We work with third parties, especially government agencies to perform Rolling Energy Providers LTD services and implement its mandate. In doing so, a third party may share information about you with us, such as your email address or mobile phone number.</p>
                    <p>You accept that your pictures and testimonials on all social media platforms about Rolling Energy Providers LTD can be used for limited promotional purposes by us. This does not include your trademark or copyrighted materials.</p>
                    <p>From time to time we may send you relevant information such as news items, enforcement notice, statutorily mandated notices and essential information to aid the implementation of our mandate. We may also share your personal information in compliance with National or international laws; crime prevention and risk management agencies and service providers.</p>

                    <h4 className="pt-2">8.0 Security</h4>
                    <p>We will always hold your information securely. To prevent unauthorized access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. This site uses Secure Sockets Layer/Transport Layer Security (SSL/TLS) to ensure secure transmission of your personal data. You should see the padlock symbol in your URL address bar once you are successfully logged into the platform. The URL address will also start with https:// depicting a secure webpage. SSL applies encryption between two points such as your PC and the connecting server. Any data transmitted during the session will be encrypted before transmission and decrypted at the receiving end. This is to ensure that data cannot be read during transmission.</p>
                    <p>Rolling Energy Providers LTD has also taken measures to comply with global Information Security Management Systems (ISMS) we therefore have put in place digital and physical security measures to limit or eliminate possibilities of data privacy breach incidents.</p>

                    <h4 className="pt-2">9.0 Data Confidentiality Rights</h4>
                    <p>Your information is regarded as confidential and will not be divulged to any third party except under legal and/or regulatory conditions. You have the right to request sight of, and copies of any and all information we keep on you, if such requests are made in compliance with the Freedom of Information Act and other relevant enactments. While Rolling Energy Providers LTD is responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with physical security protocols on our premises, dealing with only authorized officers of the Agency.</p>

                    <h4 className="pt-2">10.0 Links to Other Websites and Premises</h4>
                    <p>Our website may contain links to other websites. We are not responsible for the privacy practices of other websites. Please be sure to read the privacy policies of other websites before you submit any personal information. Opinions expressed or materials appearing on such websites are not necessarily shared or endorsed by us, and Rolling Energy Providers LTD should not be regarded as the publisher of such opinions or materials. Please be aware that we are not responsible for the privacy practices, or content of these sites. We encourage our users to be aware of when they leave our site and to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. Rolling Energy Providers LTD will not accept any responsibility for any loss or damage in whatever manner, however caused, resulting from your disclosure to third parties of personal information.</p>

                    <h4 className="pt-2">11.0 Governing Law</h4>
                    <p>This Privacy Policy is made pursuant to the Nigeria Data Protection Regulation (2019) and other relevant Nigerian laws, regulations or international conventions applicable to Nigeria. Where any provision of this Policy is deemed inconsistent with a law, regulation or convention, such provision shall be subject to the overriding law, regulation or convention.</p>
                </div>
            </div>
        </>
    )
}
export default PrivayPolicy;
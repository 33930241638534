import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/Navbar.css";

function Navbar() {

  const [navbarOpen, setNavbarOpen] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 20);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div>
      <nav className={`navbar mobile-responsive-navbar navbar-expand-lg ${isScrolled ? "sticky-desktop" : ""}`}>
        <Link to="" className="navbar-brand d-flex align-items-center navbar-left  px-lg-5">
          <img className="navbar-logo" src={require("../../assets/img/RE LOGO.png")} alt="Rolling energy logo" />
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded={navbarOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}

        >
          {navbarOpen ? (
            <i className="fas fa-times"></i>
          ) : (
            <img className="nav-toggle" src={require('../../assets/img/navbar-toggle.png')} />
          )}
        </button>
        <div className={`collapse navbar-collapse text-nav ${navbarOpen ? "show" : ""}`} id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink exact to="/" className="nav-item nav-link" activeClassName="active" onClick={closeNavbar} >
              Home
            </NavLink>
            <NavLink to="/about-us" className="nav-item nav-link" activeClassName="active" onClick={closeNavbar}>
              About Us
            </NavLink>
            <NavLink to="/services" className="nav-item nav-link" onClick={closeNavbar}>
              Services
            </NavLink>
            <NavLink to="/media" className="nav-item nav-link" activeClassName="active" onClick={closeNavbar}>
              Media
            </NavLink>
            <NavLink to="/contact-us" className="nav-item nav-link" activeClassName="active" onClick={closeNavbar}>
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;

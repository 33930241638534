import React, { useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import "../assets/css/Banner.css";

// animation
import 'aos/dist/aos.css';


function Banner() {
    const bannervideourl= "https://rollingenergy.s3.eu-north-1.amazonaws.com/REBanner.mp4";

 

  
    const bannersettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    useEffect(() => {
        const bannerContent = document.querySelector('.banner-content');
        bannerContent.classList.add('fade-in1');
        bannerContent.classList.add('fade-in2');
        bannerContent.classList.add('fade-in3');
        bannerContent.classList.add('fade-in4');
    }, []);


    //  bannner end----------------------------------------------------------
    return (

        <>
            {/* ------------------------ banner content start-------------------------- */}
            <section style={{ overflow: "hidden" }}>
                

                <Slider {...bannersettings}>
                    <div>
                        <div className="banner-main">
                            <img
                                style={{ height: "100%", width: "100%", backgroundSize: "cover" }}
                                className="banner-image"
                                src={require("../assets/img/Banner/Banner New.png")}
                                alt="Rolling Energy - Empowering the Future with Clean CNG Solutions"
                            />

                            <div className="banner-content">
                                <h2 className="fade-in1 text-dark " data-aos="fade-right">Welcome to</h2>
                                <h1 className="fade-in2" data-aos="fade-right">ROLLING ENERGY</h1>
                                <p className="fade-in3 pt-3 banner-1-para" data-aos="fade-right">
                                    <strong>Pioneering clean mobility for a sustainable future in
                                        Nigeria through harnessing the Power Compressed Natural Gas (CNG)</strong>{" "}
                                </p>
                                {/* <button type="button" className="fade-in4 btn btn-button bg rounded-pill m-margin ">
                                    BROCHURE
                                </button> */}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="banner-main">

                            {/* <video style={{ height: "100%", width: "100%", objectFit: "cover", position: "relative" }} src={bannervideourl} autoPlay loop muted className="banner-video">
                            </video> */}
                            {/* <video controls={false} style={{ height: "100%", width: "100%", objectFit: "cover", position: "relative" }} autoPlay loop muted className="banner-video">
                            <source src={bannervideourl} type="video/mp4"/></video> */}


                            <video controls={false} playsInline style={{ controls: 'none', height: "100%", width: "100%", objectFit: "cover", position: "relative" }} className="video" autoPlay muted loop>
                            <source src={bannervideourl} type="video/mp4" />
                        </video>
                      
                            <div className="banner-content">
                                <h2 className="fade-in1 text-white" data-aos="fade-right">Welcome to</h2>
                                <h1 className="fade-in2" data-aos="fade-right">ROLLING ENERGY</h1>
                                <p className=" fade-in3 pt-3 text-white" data-aos="fade-right">
                                    Pioneering clean mobility for a sustainable future in
                                    Nigeria through harnessing the Power Compressed Natural Gas (CNG)
                                    {" "}
                                </p>
                             
                            </div>
                            <span className="banner-tag"><img src={require('../assets/img/Banner/banner-tag1.png')} alt="Cng banner tag" />
                            </span>
                        </div>
                    </div>
                </Slider>
            </section>
            {/* ------------------------ banner content end-------------------------- */}

        </>
    )
}
export default Banner;
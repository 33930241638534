import React from "react";
import "../../assets/css/Media.css";

function Media1() {
    return (
        <>
            {/* <--------Social Media Icon Start-------------> */}
            <div className="social-media">
                <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
                <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
                <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
                <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
                <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
            </div>
            {/* <----------------Social Media Icon End----------------> */}



            <div className="container pt-3 0b-5">
                <div className="gallery-media1-title-main">
                    <p className="gallery-media1-title">Ground breaking event - <span>Kaduna CNG conversion Workshop </span> </p>
                </div>

                <div className="gallery-media-section1-main">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <img className="gallery-media1-img1 rounded-4" src={require('../../assets/img/Media/gallery-media1 image1.jpg')} alt="Rolling Energy Media Gallery Images - Eco-Friendly CNG Solutions and Innovations" loading="lazy" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <img className="gallery-media1-img2 rounded-4" src={require('../../assets/img/Media/gallery-media1-image2.jpg')} alt="Rolling Energy Media Gallery Images - CNG Vehicles and Sustainable Transportation Solutions" loading="lazy" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <img className="gallery-media1-img3 rounded-4" src={require('../../assets/img/Media/gallery-media1-image3.jpg')} alt="Rolling Energy Media Gallery Images - Clean and Efficient CNG-Powered Vehicles" loading="lazy" />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <img className="gallery-media1-img4 rounded-4" src={require('../../assets/img/Media/gallery-media1-image4.jpg')} alt="Rolling Energy Media Gallery Images - CNG Refueling Station and Infrastructure" loading="lazy" />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <img className="gallery-media1-img5 rounded-4" src={require('../../assets/img/Media/gallery-media1-image5.jpg')} alt="Rolling Energy Media Gallery Images - CNG-Powered Heavy Vehicles" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gallery-media1-section-images-main">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img6.jpg')} alt="Rolling Energy Media Gallery Images 6" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img7.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img8.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img9.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img10.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img11.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img12.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img13.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img14.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img15.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img16.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img17.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img18.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img19.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-media1-section-images">
                                <img className="gallery-media1-images rounded-4" src={require('../../assets/img/Media/gallery-media1-img20.jpg')} alt="images" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </>
    )
}
export default Media1;
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "../../assets/css/Media.css";
import data from '../Apiurl/meta_api';


// animation-------------------------------------------------------------------------
import AOS from "aos";
import 'aos/dist/aos.css';

function Media() {
  const [meta, setMeta] = useState(null);
  const [newsData, setNews] = useState(null);
  const [articlesData, setArticles] = useState(null);

  const fetchData = async () => {
    try {
      const result = data;
      if (result && result.length > 0) {
        const [metaPage, newsPage, articlesPage] = result;

        // Assuming you want to set metadata for the 'media_page'
        if (metaPage && metaPage.media_page) {
          setMeta(metaPage.media_page);
        }

        // Assuming you want to set news data
        if (newsPage && newsPage.news_data) {
          setNews(newsPage.news_data);
        }

        // Assuming you want to set articles data
        if (articlesPage && articlesPage.articles_page) {
          setArticles(articlesPage.articles_page);
        }
      }
    } catch (error) {
      // console.log('Error in fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  return (
    <>
      <Helmet>
        {meta &&
          meta.meta &&
          Object.values(meta.meta).map((metaItem) => (
            <meta key={metaItem.name} {...metaItem} />
          ))}
        {meta && meta.title && <title>{meta.title}</title>}
      </Helmet>

      {/* <--------Social Media Icon Start-------------> */}
      <div className="social-media">
        <a target="blank" href="https://www.facebook.com/rollingenergyltd"><i class="fa-brands fa-facebook "></i></a>
        <a target="blank" href="https://www.instagram.com/rolling_energy/"><i class="fa-brands fa-instagram"></i></a>
        <a target="blank" href="https://twitter.com/rolling_energy"><i class="fa-brands fa-x-twitter"></i></a>
        <a target="blank" href="https://www.linkedin.com/company/rollingenergy/"><i class="fa-brands fa-linkedin"></i></a>
        <a href="mailto:enquiries@rollingenergyltd.com"><i class="fa-regular fa-envelope"></i></a>
      </div>
      {/* <----------------Social Media Icon End----------------> */}

      <div className="container-fluid">
        <div className="container pt-3">
          {/* -------------First Card Section Start-------------- */}

          <section>
            <p className="news-text">news</p>
            <div className="row mt-3 mb-5">
              {newsData &&
                Object.keys(newsData).map((key) => (
                  <div key={key} className="col-lg-4 col-md-6 col-sm-12 m-padding" data-aos="fade-up">
                    <div className="card news-card">
                      <iframe
                        className="youtube-video"
                        src={newsData[key].youtube_embed}
                        width="400"
                        height="300"
                        title={newsData[key].title}
                        alt=""
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                      <div className="card-body">
                        <a target="_blank" href={newsData[key].link} className="card-media-title">
                          <p>{newsData[key].title}</p>
                        </a>
                        <p className="card-text">{newsData[key].content}</p>
                        <a
                          target="_blank"
                          href={newsData[key].link}
                          className="btn btn-button read-more-button"
                        >
                          <p>Read More</p>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
          {/* -------------First Card Section End-------------- */}

          {/* -------------Second Card Section Start----------- */}
          <section>
            <div className="pt-3">
              <p className="news-text">
                Articles
              </p>
              <div className="row mt-3 mb-5">
                {articlesData &&
                  Object.keys(articlesData).map((key) => (
                    <div key={key} className="col-lg-4 col-md-6 col-sm-12 m-padding" data-aos="fade-up">
                      <div className="card news-card">
                        <img className="article-image" src={articlesData[key].img} alt="" loading="lazy" />
                        <div className="card-body">

                          <a href={articlesData[key].link} target="_blank" className="article-card-title-link">
                            <p className="article-card-title">{articlesData[key].title}</p>
                          </a>

                          <p className="article-card-text">{articlesData[key].content}</p>
                          <a
                            target="_blank"
                            href={articlesData[key].link}
                            className="btn btn-button read-more-button"
                          >
                            <p>Read More</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
          {/* -------------Second Card Section End-------------- */}

          {/* -------------Third Card Section Start-------------- */}
          <section className="gallery">
            <div className="pt-3">
              <p className="news-text">
                Galleries
              </p>
              <div className="row mt-3 mb-5">
                <div className="col-lg-4 col-md-6 col-sm-12  m-padding" data-aos="fade-up">
                  <div class="card news-card pb-4" >
                    <img className="gallery-image" src={require('../../assets/img/Media/ground-breaking-event.jpg')} alt="" loading="lazy" />
                    <div class="card-body">
                      <li class="article-card-gallery-title pt-2"><NavLink className='gallery-link' to="/ground-breaking-event-kaduna-cng-conversion-workshop" onClick={scrollToTop}>Ground breaking event - Kaduna CNG conversion Workshop</NavLink></li>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12  m-padding" data-aos="fade-up">
                  <div class="card news-card pb-4" >
                    <img className="gallery-image" src={require('../../assets/img/Media/cng-presidential-partnership.jpg')} alt="" loading="lazy" />
                    <div class="card-body">
                      <li class="article-card-gallery-title pt-2"><NavLink className='gallery-link' to="/presidential-cng-initiative-partnership-signing-ceremony" onClick={scrollToTop}> Presidential CNG Initiative Partnership Signing Ceremony</NavLink></li>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 article-last-card-padding  m-padding" data-aos="fade-up">
                  <div class="card news-card pb-4" >
                    <img className="gallery-image" src={require('../../assets/img/Media/nigeria-resolution-event-2023.jpg')} alt="" loading="lazy" />
                    <div class="card-body">
                      <li class="article-card-gallery-title pt-2"><NavLink className='gallery-link' to="/nigeria-s-cng-revolution-an-exclusive-stakeholders-event-2023" onClick={scrollToTop}>Nigeria’s CNG Revolution - An Exclusive Stakeholders Event 2023</NavLink></li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* -------------Third Card Section End-------------- */}
        </div>
      </div>
    </>
  )
}
export default Media;
const data1 = [

    {
        home_page: {
            schema:{

          
            schema1: {
                "@context": "https://schema.org",
                "@type": "Corporation",
                "name": "Rolling Energy Private Limited",
                "alternateName": "Rolling Energy",
                "url": "https://rollingenergyltd.com/",
                "logo": "https://www.rollingenergyltd.com/Rolling-Energy.png",
                "sameAs": [
                    "https://www.facebook.com/rollingenergyltd",
                    "https://www.instagram.com/rolling_energy/",
                    "https://www.linkedin.com/company/rollingenergy/",
                    "https://www.rollingenergyltd.com/",
                    "https://twitter.com/rolling_energy"
                ]
            },
            //--Featured Snippets--//
            schema2: {
                "@context": "http://www.schema.org",
                "@type": "product",
                brand: "Rolling Energy",
                name: "CNG and Refilling Stations",
                image:
                    "https://www.rollingenergyltd.com/static/media/Banner%20New.dc818b6054bde51beb49.png",
                description:
                    "Conversion of Vehicles from Fuel to CNG, with Refilling Stations Across Nigeria.",
            },
            // --Voice Search Optimization--//
            schema3: {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity: [
                    {
                        "@type": "Question",
                        name: "What is Compressed Natural Gas (CNG)?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Compressed natural gas (CNG) is a clean and environmentally friendly alternative fuel made by compressing natural gas.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "How does CNG benefit the environment?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "CNG produces fewer emissions than traditional fossil fuels, reducing the carbon footprint and contributing to cleaner air.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "How does the cost of Compressed Natural Gas (CNG) compare to traditional fuels like petrol and diesel?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "CNG is significantly more affordable than petrol and diesel, with potential savings of up to 30-40% on fuel costs, making it a cost-effective and sustainable choice.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "What vehicles can be converted to CNG?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Various vehicles, including bikes, tricycles, cars, trucks, buses, and power generators, can be converted to run on CNG.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Are CNG conversion kits compatible with all vehicle models?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Rolling Energy provides versatile CNG conversion kits compatible with a wide range of vehicle models for both personal and commercial use.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "How does Rolling Energy contribute to the CNG market in Nigeria?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Rolling Energy is a leading importer and installer of CNG kits, making clean energy accessible through strategic installations across major cities in Nigeria.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "The best Nigerian vehicles converted to CNG?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Rolling Energy is the best fuel for CNG conversion companies across Nigeria.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "What are the benefits of becoming a CNG Reseller with Rolling Energy?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Resellers gain exclusive access to competitive CNG conversion kits, business support, and the opportunity to contribute to the growth of sustainable energy.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "CNG as efficient as traditional fuels?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "CNG offers comparable or even better performance than traditional fuels, with the added benefit of being more cost-effective and environmentally friendly.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Does Rolling Energy provide training for CNG kit installation?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Yes, our CNG Reseller Program includes hands-on training sessions conducted by seasoned professionals in the industry.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "How does Virtual Pipelines / CNG Delivery work?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Virtual Pipelines facilitates the delivery of CNG to your doorstep for businesses and individuals. Contact us, place your order, and we will be there to refill your gas cylinders.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Is CNG safe for vehicle operation?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Yes, CNG is safe for vehicle operation. Rolling Energy emphasizes safety guidelines and conducts regular inspections to ensure compliance with industry standards.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Does Rolling Energy provide maintenance services for CNG vehicles?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Yes, Rolling Energy offers comprehensive maintenance services for CNG vehicles, covering routine check-ups and specialized diagnostics.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Can I convert my power generator to run on CNG?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Yes, Rolling Energy provides CNG conversion kits and services for power generators, offering an efficient and eco-friendly energy solution.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Can I purchase CNG conversion kits directly from Rolling Energy?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Yes, you can purchase CNG conversion kits directly from Rolling Energy. Contact our sales team for more information.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "who providing Best Quality CNG Conversion Kits in Nigeria?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Rolling Energy maintains quality control through rigorous testing and compliance with industry standards. Our kits are also backed by government approval.",
                        },
                    },
                    {
                        "@type": "Question",
                        name: "Who Providing Best CNG service in Nigeria?",
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: "Rolling Energy is providing the best CNG service across Nigeria.",
                        },
                    },
                ],
            }
              }
        }
    }
]
export default data1;